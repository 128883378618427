import { Children, ContextType, isValidElement, KeyboardEvent, ReactElement, ReactNode } from 'react';

/**
 * Iterates through children that are typically specified as `props.children`,
 * but only maps over children that are "valid components".
 *
 * The mapFunction provided index will be normalized to the components mapped,
 * so an invalid component would not increase the index.
 *
 * @param {?*} children Children tree container.
 * @param {function(*, int)} func.
 * @param {*} context Context for func.
 * @return {object} Object containing the ordered map of results.
 */
export function mapReactChildren<ElementType>(
  children: ReactNode,
  func: (child: ReactElement<ElementType>, index: number) => ReactNode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: ContextType<any>
) {
  let index = 0;

  return Children.map(children, (child) => {
    if (!isValidElement(child)) {
      return child;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return func.call(context, child, index++);
  });
}

export const handleSpaceAndEnterKeyDown =
  <ElementType>(callback: (e: KeyboardEvent<ElementType>) => void) =>
  (e: KeyboardEvent<ElementType>): void => {
    if (e.code === 'Space' || e.code === 'Enter') {
      return callback(e);
    }
  };

export function isSafari() {
  const userAgent = window.navigator.userAgent;
  const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
  return !!safari;
}

export function elementIsInsideAnother(outer: HTMLElement, inner: HTMLElement) {
  const outerRect = outer.getBoundingClientRect();
  const innerRect = inner.getBoundingClientRect();

  return (
    innerRect.x >= outerRect.x &&
    innerRect.x + innerRect.width <= outerRect.x + outerRect.width &&
    innerRect.y >= outerRect.y &&
    innerRect.y + innerRect.height <= outerRect.y + outerRect.height
  );
}

export function mousePositionIsInsideElement(event: MouseEvent, element: HTMLElement, offset = 30) {
  const rect = element.getBoundingClientRect();
  const x = event.clientX;
  const y = event.clientY;

  return x + offset >= rect.x && x - offset <= rect.x + rect.width && y + offset >= rect.y && y - offset <= rect.y + rect.height;
}
