import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import axios from 'axios';
import { StatsigProvider } from 'statsig-react';

import { Feedback } from '@components/Feedback';
import { getSession } from '@utils/session';

import { AppProviders } from '../../context-providers';

const StatsigEnvironmentMap: Record<ImportMetaEnv['VITE_ENV'], string> = {
  dev: 'development',
  stg: 'staging',
  prod: 'production'
};

export function AppContainer() {
  // In the future we should remove this and use the "request" package in all the places
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const sessionData = getSession();
        if (!config.params) {
          config.params = {};
        }
        if (sessionData.token) {
          config.headers['Authorization'] = `Bearer ${sessionData.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <GTMProvider state={import.meta.env.VITE_ENV === 'prod' ? { id: 'GTM-KR65H3VC' } : undefined}>
      <AppProviders>
        <StatsigProvider
          waitForInitialization
          user={{}}
          sdkKey='client-xzVxzeCKoSMi0VoDNariJXwC2gFSBVpWUkxtHFiMvm4'
          options={{ environment: { tier: StatsigEnvironmentMap[import.meta.env.VITE_ENV ?? 'dev'] } }}
        >
          <Feedback />
          <Outlet />
        </StatsigProvider>
      </AppProviders>
    </GTMProvider>
  );
}
