import { TagType } from '@query';

export const TagListMockData: TagType[] = [
  {
    name: 'Technology',
    slug: 'technology',
    description: 'Posts related to the latest technology trends and innovations.',
    createdAt: '2024-01-10T08:15:30Z',
    updatedAt: '2024-01-10T08:15:30Z',
    color: '#FF5733',
    fontColor: '#FFFFFF'
  },
  {
    name: 'Health & Wellness',
    slug: 'health-wellness',
    description: 'Insights and tips for a healthy lifestyle and well-being.',
    createdAt: '2024-02-15T09:45:00Z',
    updatedAt: '2024-02-15T09:45:00Z',
    color: '#8E44AD',
    fontColor: '#FFFFFF'
  },
  {
    name: 'Travel',
    slug: 'travel',
    description: 'Guides, tips, and stories for travel enthusiasts.',
    createdAt: '2024-03-05T10:30:45Z',
    updatedAt: '2024-03-05T10:30:45Z',
    color: '#3498DB',
    fontColor: '#FFFFFF'
  }
];

export const TagListMockDataMap: Map<string, TagType> = new Map(TagListMockData.map((tag) => [tag.slug, tag]));
