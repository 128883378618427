import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getAllGroupHookups, getGroupHookupCategories, getGroupHookupDetails, getHighlightedGroupHookups } from '../query-fn';

export const GroupHookupQueryStore = createQueryKeys('group-hookup', {
  details: (uuid: string) => ({
    queryKey: [uuid],
    queryFn: (ctx) => getGroupHookupDetails({ ...ctx, params: { uuid } })
  }),
  list: {
    queryKey: null,
    queryFn: () => getAllGroupHookups()
  },
  'get-categories': {
    queryKey: null,
    queryFn: (ctx) => getGroupHookupCategories(ctx)
  },
  highlighted: {
    queryKey: null,
    queryFn: (ctx) => getHighlightedGroupHookups(ctx)
  }
});
