import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import UsersIcon from '@mui/icons-material/GroupRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ReviewsRoundedIcon from '@mui/icons-material/ReviewsRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';

import { ROLES_MAP } from '@utils/permissions';

export type NavigationRoute = {
  id: string;
  label: string;
  icon: React.ReactNode;
  to?: string;
  children?: NavigationRoute[];
  allowedRoles: ROLES_MAP[];
};

export const NavigationRoutes: NavigationRoute[] = [
  {
    id: 'login',
    label: 'Login',
    icon: null,
    to: 'login',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'forgot-password',
    label: 'Forgot Password',
    icon: null,
    to: 'forgot-password',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'register',
    label: 'Sign Up',
    icon: null,
    to: 'register',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'home',
    label: 'Home',
    icon: <HomeRoundedIcon />,
    to: 'home',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'users',
    label: 'Users',
    icon: <UsersIcon />,
    to: 'users',
    allowedRoles: [ROLES_MAP.admin]
  },
  {
    id: 'hookups',
    label: 'Hookups',
    icon: <Diversity2RoundedIcon />,
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client],
    children: [
      {
        id: 'timecycles',
        label: 'Timecycles',
        icon: <EventRepeatRoundedIcon />,
        to: 'timecycles',
        allowedRoles: [ROLES_MAP.admin]
      },
      {
        id: '1-to-1',
        label: '1-to-1 Hookups',
        icon: <WebhookRoundedIcon />,
        to: 'timeslots',
        allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
      },
      {
        id: 'group-hookups',
        label: 'Group Hookups',
        icon: <Diversity2RoundedIcon />,
        to: 'group-hookups',
        allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
      }
    ]
  },
  {
    id: 'my-network',
    label: 'My Network',
    icon: <Diversity3RoundedIcon />,
    to: 'my-network',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'reviews',
    label: 'Reviews',
    icon: <ReviewsRoundedIcon />,
    to: 'reviews',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'referrals',
    label: 'Referrals',
    icon: <GroupAddOutlinedIcon />,
    to: 'referrals',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },

  {
    id: 'champions',
    label: 'Champions',
    icon: <EmojiEventsRoundedIcon />,
    to: 'champions',
    allowedRoles: [ROLES_MAP.client, ROLES_MAP.admin]
  },
  {
    id: 'member-perks',
    label: 'Member Perks',
    icon: <VerifiedRoundedIcon />,
    to: 'member-perks',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'invite',
    label: 'Invite Friends',
    icon: <ShareRoundedIcon />,
    to: 'invite',
    allowedRoles: [ROLES_MAP.client]
  },
  {
    id: 'chat',
    label: 'Chat',
    icon: <ChatIcon />,
    to: 'chat',
    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'settings',
    label: 'Settings',
    icon: <SettingsOutlinedIcon />,
    to: 'settings',

    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'my-profile',
    label: 'My Profile',
    icon: <AccountCircleOutlinedIcon />,
    to: 'profile',

    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'profile',
    label: 'Profile',
    icon: <AccountCircleOutlinedIcon />,
    to: 'profile/:userId?',

    allowedRoles: [ROLES_MAP.admin, ROLES_MAP.client]
  },
  {
    id: 'edit-user',
    label: 'Edit User',
    icon: <UsersIcon />,
    to: 'users/:userId/edit',
    allowedRoles: [ROLES_MAP.admin]
  }
];

export const AppBarRoutes = NavigationRoutes.filter((route) =>
  ['home', 'calendar', '1-to-1', 'hookups', 'group-hookups', 'member-perks', 'champions', 'my-network', 'hookups', 'timecycles', 'users', 'invite'].includes(
    route.id
  )
);
