import { forwardRef } from 'react';

import { Stack, Typography } from '..';
import { Button, ButtonProps } from './button';

export type MobileIconButtonPropsType = {
  isMobile?: boolean;
  icon: React.ReactNode;
  text: string;
} & ButtonProps;

export const MobileIconButton = forwardRef<HTMLDivElement, MobileIconButtonPropsType>((props, ref) => {
  const { icon, text, isMobile, ...rest } = props;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <Button ref={ref} {...rest}>
      {isMobile ? (
        <Stack alignItems='center'>
          {icon}
          <Typography variant='body2'>{text}</Typography>
        </Stack>
      ) : (
        icon
      )}
    </Button>
  );
});

MobileIconButton.displayName = 'MobileIconButton';
