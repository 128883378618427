import { styled } from '@mui/material/styles';
import MUITooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => <MUITooltip arrow enterTouchDelay={0} {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    cursor: 'help',
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.gray.dark
    }
  })
);
