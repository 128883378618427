import { ThemeProvider as MUIThemProvider } from '@mui/material';
import { DefaultTheme } from '@mui/system';

import { defaultTheme } from './default-theme';

type ThemeProviderType = { children: React.ReactNode; theme?: DefaultTheme };

export function ThemeProvider(props: ThemeProviderType) {
  return <MUIThemProvider theme={props.theme ?? defaultTheme}>{props.children}</MUIThemProvider>;
}
