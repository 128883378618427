export function getMsgMin(key: string, value: number) {
  return `${key} must be at least ${value} characters`;
}

export function getMsgMax(key: string, value: number) {
  return `${key} must be less than ${value} characters`;
}

export function getInvalidFieldMessage(key: string) {
  return `You need to provide a valid ${key}`;
}
