import { DateTime } from 'luxon';

import { UserMock } from '@mocks/data';

import { HookupDataType, HookupParticipantDataType } from '../../query/types';
import { GroupHookupsListMockData } from './group-hookup';

export const FutureHookupsForUserMockData: HookupDataType[] = GroupHookupsListMockData.map((gh, i) => {
  const date = DateTime.fromISO(gh.startDate, { zone: 'utc' }).toLocal();

  return {
    uuid: `uuid${i}`,
    firstParticipant: UserMock as unknown as HookupParticipantDataType,
    secondParticipant: UserMock as unknown as HookupParticipantDataType,
    firstParticipantGoogleEventId: `event${i}`,
    secondParticipantGoogleEventId: `event${i}`,
    videoCallId: `call${i}`,
    startDateTime: date.toISO() as string,
    endDateTime: date.plus({ minutes: 30 }).toISO() as string,
    createdOn: date.toISO() as string,
    timeCycleId: `cycle${i}`,
    timeCycleName: `Cycle ${i}`,
    hookupStatus: 'status',
    isDefaultTimeCycle: false,
    rescheduled: false,
    defaultTimeCycle: false,
    confirmed: true
  };
});
