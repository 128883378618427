import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { Box } from '@tulp';

import { InfiniteScrollProps } from './types';

export const InfiniteScroll = React.forwardRef<HTMLDivElement, InfiniteScrollProps>(
  ({ loadMore, isLoading, hasMore, hasZeroItems, intersectionComponent, ...props }, parentRef) => {
    hasZeroItems ??= !React.Children.count(props.children);

    const { ref } = useInView({
      // according to https://stackoverflow.com/a/66069157/9008899 the threshold when is 1.0 doesn't work in safari
      // this was affecting some users, discovered in sentry
      threshold: 0.9,
      onChange: (inView) => {
        if (inView && !isLoading && hasMore) {
          loadMore();
        }
      }
    });

    if (hasZeroItems) {
      return null;
    }

    return (
      <Box {...props} ref={parentRef}>
        {props.children}
        {!isLoading && hasMore && (
          <Box ref={ref} component={intersectionComponent}>
            {/* This is necessary to avoid unexpected behaviors on Safari: */}
            &nbsp;
          </Box>
        )}
      </Box>
    );
  }
);

InfiniteScroll.displayName = 'InfiniteScroll';
