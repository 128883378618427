import { UserDataType } from '@query';

export const UserMock: UserDataType = {
  uuid: '123456',
  id: '789',
  username: 'johndoe@gmail.com',
  firstName: 'John',
  lastName: 'Doe',
  fullName: 'John Doe',
  linkedInUrl: 'https://www.linkedin.com/in/johndoe',
  phoneNumber: '1234567890',
  jobPosition: 'Software Engineer',
  roles: ['ROLE_USER'],
  countryCode: 'US',
  city: 'New York',
  referrer: 'Jane Smith',
  reviews: 'Great professional',
  pastMatches: [],
  futureHookupMeets: [],
  companyName: 'ABC Company',
  companySize: '100-500',
  industry: 'Technology',
  yearsOfExperience: '5-10',
  seniorityLevel: 'Senior',
  personalInformation: 'Lorem ipsum dolor sit amet',
  timeCycles: [],
  blockedUsers: [],
  imageUrl: 'https://example.com/profile.jpg',
  subscribeToken: 'abcdefg',
  lastActivity: '2022-01-01',
  unsubscribeDate: null,
  location: {
    lat: 40.7128,
    lng: -74.006,
    name: 'New York City'
  },
  mailingPreferences: {
    marketing: true,
    notifications: true
  },
  pauseNotificationsStartDate: null,
  pauseNotificationsEndDate: null,
  active: true,
  verified: true,
  linkedWithGoogleCalendar: true,
  loggedWithLinkedIn: true,
  acceptTermsAndConditions: true,
  subscribe: true
};

export const UserAdminMock: UserDataType = { ...UserMock, roles: ['ROLE_ADMIN'] };

export const UserMockWithPasswordData = { ...UserMock, password: 'mockPassword' };
