import { AxiosResponse } from 'axios';

import { request } from '@request';
import { UnknownObject } from '@tulp';

export function downloader(outFile: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (response: AxiosResponse<any, any>) => {
    const header = response.headers['content-disposition'];
    const filename = header ? /filename="(.+)?"/g.exec(header)?.[1] ?? '' : outFile;
    if (window.navigator?.msSaveOrOpenBlob) {
      const csvData = new Blob([response.data], { type: 'text/csv' });
      window.navigator.msSaveOrOpenBlob(csvData, filename);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }
  };
}

export async function downloadGet(url: string, params: UnknownObject, filename: string) {
  const data = await request.get(url, {
    responseType: 'blob',
    params: params
  });
  return downloader(filename)(data);
}
