import { createQueryKeys } from '@lukemorales/query-key-factory';

import { videoSdkGenerateToken, videoSdkValidateRoom } from '../query-fn';
import { VideoSdkGenerateTokenQueryParamsType, VideoSdkValidateRoomQueryParamsType } from '../types';

export const VideoSdkQueryStore = createQueryKeys('video-sdk', {
  'generate-token': (params: VideoSdkGenerateTokenQueryParamsType) => ({
    queryKey: [params],
    queryFn: (ctx) => videoSdkGenerateToken({ ...(ctx ?? {}), params: params })
  }),
  'validate-room': (params: VideoSdkValidateRoomQueryParamsType) => ({
    queryKey: [params],
    queryFn: () => videoSdkValidateRoom(params)
  })
});
