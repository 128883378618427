import * as z from 'zod';

export const TagFormValidationSchema = z.object({
  tags: z
    .object({
      name: z.string().min(1),
      description: z.string().min(1),
      color: z.string().min(1),
      fontColor: z.string().min(1)
    })
    .array()
});

export type TagFormValidationSchemaType = z.infer<typeof TagFormValidationSchema>;
