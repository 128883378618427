import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getChatToken } from '../query-fn';

export const ChatQueryStore = createQueryKeys('chat', {
  token: {
    queryKey: null,
    queryFn: (ctx) => getChatToken(ctx)
  }
});
