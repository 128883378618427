import { ENVIRONMENTS } from '@config/environments';

export const LANGUAGES = [
  {
    lang: 'en',
    text: 'English',
    icon: 'flag-en'
  }
];

export const BASE_URL = ENVIRONMENTS.BACKEND_URL;

export const minLengthPassword = 8;
export const maxLengthPassword = 250;
export const minLengthUsername = 4;
export const maxLengthUsername = 100;

export const company = 'SalesHookup';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USER_MANDATORY_FIELDS = ['firstName', 'lastName', 'jobPosition', 'linkedInUrl', 'location.name'];

/**
 *
 * CHAT Constants
 *
 */

export const NOTIFICATION_LEVEL = {
  DEFAULT: 'default',
  MUTED: 'muted'
};

export const MessageStatus = {
  Sending: 'Sending',
  Sent: 'Sent',
  Delivered: 'Delivered',
  Failed: 'Failed',
  None: 'none (incoming)',
  Read: 'Read'
};

export const MAX_FILE_SIZE = 52428800;
export const COPY_SUCCESS_MESSAGE = 'Message copied.';
export const UNEXPECTED_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const CONNECTION_ERROR_MESSAGE = 'No internet connection.';
export const NOTIFICATION_TIMEOUT = 4000;
export const ERROR_MODAL_MESSAGES = {
  ADD_PARTICIPANT: {
    title: 'Unable to add participant',
    description: 'You don’t have permission to add participants to the conversation'
  },
  CHANGE_CONVERSATION_NAME: {
    title: 'Unable to save Conversation name',
    description: 'Only creators of the Conversation can edit the Conversation name.'
  }
};
export const CONVERSATION_MESSAGES = {
  CREATED: 'Conversation created.',
  NAME_CHANGED: 'Conversation name changed.',
  LEFT: 'You left the conversation.'
};

export const PARTICIPANT_MESSAGES = {
  ADDED: 'Participant added.',
  REMOVED: 'Participant removed.'
};

export const CONVERSATION_PAGE_SIZE = 30;

interface NotificationCodeMap {
  [keyCode: string]: string;
}

export const NotificationMessage: NotificationCodeMap = {
  1001: 'Users are matched',
  1002: 'You have a SalesHookup meeting tomorrow',
  1003: 'Your SalesHookup meeting will start in less than 1 hour',
  1004: 'You have a new accepted invitation',
  1005: "Unfortunately you weren't matched this week",
  1006: 'You can choose your new timeslot',
  1007: 'Your hookup was rescheduled',
  1008: 'You have a request to reschedule your next meeting',
  1009: 'Your hookup was canceled',
  1010: 'You have a new hookup meeting'
};

export const NotificationLink: NotificationCodeMap = {
  1001: 'hookups',
  1002: 'hookups',
  1003: 'hookups',
  1004: 'hookups',
  1005: 'hookups',
  1006: 'timeslots',
  1007: 'hookups',
  1008: 'hookups',
  1009: 'hookups',
  1010: 'hookups'
};

export const SeniorityLevel: NotificationCodeMap = {
  STARTER: 'Starter',
  SENIOR: 'Senior',
  MANAGER: 'Manager',
  DIRECTOR: 'Director'
};

export const CompanySize: NotificationCodeMap = {
  '1_SIZE_1_TO_10': '1-10',
  '2_SIZE_11_TO_50': '11-50',
  '3_SIZE_51_TO_200': '51-200',
  '4_SIZE_201_TO_500': '201-500',
  '5_SIZE_501_TO_1000': '501-1000',
  '6_SIZE_1001_TO_5000': '1001-5000',
  '7_SIZE_5001_TO_10000': '5001-10000',
  '8_SIZE_10000_TO_UP': '10,001+'
};

export const YearsExperience: NotificationCodeMap = {
  '1_TYPE_LESS_1_YEAR': 'Less than 1 year',
  '2_TYPE_1_TO_2_YEARS': '1 to 2 years',
  '3_TYPE_3_TO_5_YEARS': '3 to 5 years',
  '4_TYPE_6_TO_10_YEARS': '6 to 10 years',
  MORE_THAN_10_YEARS: 'More than 10 years'
};

export const RegisterForm: NotificationCodeMap = {
  4016: 'There is already a user registered with the email provided.',
  4019: 'Email has already been taken.',
  GENERAL: 'Email has already been taken.',
  NO_GOAL_SELECTED: 'You must choose at least one goal.'
};

export const SettingsForm: NotificationCodeMap = {
  GENERAL: 'Wrong password',
  TITLE: 'Settings',
  USERNAME_LABEL: 'Email',
  USERNAME_PLACEHOLDER: 'Enter your email'
};

export const Reschedule: NotificationCodeMap = {
  CONFIRM_CANCEL_TEXT:
    'It looks like you and your hookup are having difficulty rescheduling. The platform is limited to 2 reschedules to prevent misuse of the platform. If you would like to continue with your hookup please select a timeslot. Press "SAVE" if you prefer to cancel the hookup.',
  CONFIRM_CANCEL_TITLE: 'Reschedule cancel',
  HOOKUP_CANCEL_ERROR: 'The hookup has already been cancelled!'
};

export const ResetPassword: NotificationCodeMap = {
  CONFIRM_CANCEL_TEXT:
    'It looks like you and your hookup are having difficulty rescheduling. The platform is limited to 2 reschedules to prevent misuse of the platform. If you would like to continue with your hookup please select a timeslot. Press "SAVE" if you prefer to cancel the hookup.',
  CONFIRM_CANCEL_TITLE: 'Reschedule cancel',
  CHANGE: 'Change',
  LOGIN: 'Let’s start!',
  PASSWORD_LABEL: 'New password',
  PASSWORD_PLACEHOLDER: 'Enter a new password',
  PASSWORD_REPEAT_LABEL: 'Repeat the password',
  PASSWORD_REPEAT_PLACEHOLDER: 'Repeat the password',
  SUCCESS_SUBTITLE: 'Your password has been successfully changed',
  SUCCESS_TITLE: 'Success',
  TITLE: 'password',
  USERNAME_LABEL: 'Email'
};

export const RoutePass: NotificationCodeMap = {
  MAX_NUM_OF_POINTS: 'Maximum number of points',
  CHOOSE_ROUTE_POINT: 'Choose route point',
  BORDER_CROSSING: 'Border Crossing',
  ROUTE_POINT: 'Route Point',
  BORDER_CROSSING_CHECKPOINT: 'Border Crossing Checkpoint'
};

export const Country: NotificationCodeMap = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos Islands',
  CD: 'Congo',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: 'Ivory Coast',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FM: 'Federated States of Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LB: 'Lebanon',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint Martin (French part)',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PR: 'Puerto Rico',
  PS: 'Palestine',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RO: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten (Dutch part)',
  SY: 'Syria',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TL: 'East Timor',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela, Bolivarian Republic of',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WS: 'Samoa',
  YE: 'Yemen',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};
