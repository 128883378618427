import { Box, Button, Stack, Typography } from '@tulp';

import errorEmail from './error_email.svg';

const reportProblemViaEmail = `mailto:hello@saleshookup.com`;

export function ErrorPage() {
  return (
    <Stack alignItems='center' spacing={2}>
      <Box>
        <img alt='error email' src={errorEmail} />
      </Box>
      <Typography variant='title' textAlign='center'>
        Sorry, we broke something!
      </Typography>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='subtitle1' gutterBottom paragraph>
          We have already reported the problem, but if you want to add more details you can
        </Typography>
        <Button variant='text' href={reportProblemViaEmail}>
          Write us by clicking this button
        </Button>
      </Box>
    </Stack>
  );
}
