import * as z from 'zod';

import { getMsgMax, getMsgMin } from './common-messages';

export const CreateGroupHookupValidationSchema = z.object({
  title: z.string().min(3, getMsgMin('Title', 3)).max(70, getMsgMax('Title', 70)),
  description: z.string().min(10, getMsgMin('Description', 10)).max(1000, getMsgMax('Description', 1000)).optional(),
  category: z.string(),
  startDate: z.string(),
  duration: z.number(),
  // This feature is hidden behind a feature flag, so it's optional
  tags: z.array(z.string()).optional(),
  // Only used for "big events"
  maxParticipants: z.number().optional()
});
