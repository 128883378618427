import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getAuthUserAllTimeCycle } from '../query-fn';

export const TimecycleQueryStore = createQueryKeys('timecycle', {
  all: {
    queryKey: null,
    queryFn: (ctx) => getAuthUserAllTimeCycle(ctx)
  }
});
