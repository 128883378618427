import parsePhoneNumber from 'libphonenumber-js';
import * as z from 'zod';

import { getInvalidFieldMessage, getMsgMax, getMsgMin } from './common-messages';

export const UserNameValidationSchema = z
  .string()
  .email({ message: getInvalidFieldMessage('email') })
  .min(4, getMsgMin('Username', 4))
  .max(100, getMsgMax('Username', 100));

export const PasswordValidationSchema = z
  .string()
  .min(8, getMsgMin('Password', 8))
  .max(250, getMsgMax('Password', 250))
  .regex(
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\s])(?=\S+$).{8,}$/,
    'Password must contain at least one number, one uppercase letter, one lowercase letter, one special character and no spaces'
  );

export const LinkedInURLValidationSchema = z
  .string()
  .regex(/^(http(s)?:\/\/)?(w{3}\.)?(((?!ww)..\.)?linkedin\.com\/(in|profile|pub)\/(.)+)$/, getInvalidFieldMessage('LinkedIn URL'));

export const LocationValidationSchema = z.object(
  {
    lat: z.number(),
    lng: z.number(),
    name: z.string()
  },
  { errorMap: () => ({ message: getInvalidFieldMessage('location') }) }
);

/**
 * Zod schema that validates a phone number using `libphonenumber-js`.
 * Attempts to parse the provided value with a default country of `FI`.
 *
 * If the phone number is valid, the schema transforms the phone number into
 * an international format (e.g. `+358401234567`).
 */
export const PhoneNumberValidationSchema = z.string().transform((value, ctx) => {
  if (!value) return '';

  const phoneNumber = parsePhoneNumber(value);

  if (!phoneNumber?.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: getInvalidFieldMessage('phone number')
    });
    return z.NEVER;
  }

  return phoneNumber.formatInternational();
});
