import axios, { AxiosRequestConfig, AxiosResponse, GenericAbortSignal, ResponseType } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { refreshAuthLogic } from '@query';
import { getSession } from '@utils/session';
import { UnknownObject } from '@tulp';

export type RequestOptions<TData extends UnknownObject = UnknownObject> = {
  data?: TData;
  params?: object;
  headers?: object;
  timeout?: number;
  responseType?: ResponseType;
  signal?: GenericAbortSignal;
  refreshToken?: string;
};

export class request {
  static async get(url: string, options?: RequestOptions): Promise<AxiosResponse> {
    return this._request('get', url, options);
  }

  static async post(url: string, options?: RequestOptions): Promise<AxiosResponse> {
    return this._request('post', url, options);
  }

  static async put(url: string, options?: RequestOptions): Promise<AxiosResponse> {
    return this._request('put', url, options);
  }

  static async patch(url: string, options?: RequestOptions): Promise<AxiosResponse> {
    return this._request('patch', url, options);
  }

  static async delete(url: string, options?: RequestOptions): Promise<AxiosResponse> {
    return this._request('delete', url, options);
  }

  private static async _request(method: AxiosRequestConfig['method'], url: string, options?: RequestOptions) {
    const { data, params, headers, timeout, responseType, signal } = options || {};

    // This logic is repeated because we don't use the request package in all the places
    createAuthRefreshInterceptor(axios, refreshAuthLogic);

    // This logic is repeated because we don't use the request package in all the places
    axios.interceptors.request.use(
      (config) => {
        const sessionData = getSession();
        if (!config.params) {
          config.params = {};
        }
        if (sessionData.token) {
          config.headers['Authorization'] = `Bearer ${sessionData.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const response = await axios(url, {
      method,
      data: ['get', 'delete'].includes(method ?? '') ? null : data,
      params,
      headers,
      timeout,
      responseType,
      signal
    });

    return response;
  }
}
