import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { CssBaseline, styled, Toolbar, Tooltip } from '@mui/material';
import { AppBarRoutes } from '@routes';
import * as Sentry from '@sentry/browser';
import { useGate } from 'statsig-react';

import { useInitChat } from '@chat-app/hooks/use-init-chat';
import { useInitForum } from '@feed-app/hooks';

import { OnboardingSideDrawer } from '@components/Onboarding';
import { TutorialDialog } from '@components/TutorialDialog';
import { useGetAuthenticatedUser } from '@query';
import { Box, Fab, useToggleState } from '@tulp';

import { SideDrawer } from '.';
import { AppBar } from './app-bar';

const onboardingDrawerWidth = 400;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${onboardingDrawerWidth}px`
  })
}));

export function RegisteredUserAppContainer() {
  const [tutorial, setTutorial] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const { value: isOnboardingSideDrawerFeatureEnabled } = useGate('onboarding_side_drawer');

  const { data: authenticatedUser } = useGetAuthenticatedUser();

  const [isOnboardingOpen, openOnboarding, closeOnboarding] = useToggleState();

  function handleLeftDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  function handleTutorialClose(reason: string) {
    if (reason !== 'backdropClick') {
      sessionStorage.removeItem('show_tutorial');
      setTutorial(false);
    }
  }

  useEffect(() => {
    Sentry.setUser({
      id: authenticatedUser.uuid,
      email: authenticatedUser.username,
      username: authenticatedUser.fullName
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = '#EDEEF0';
    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, []);

  // Hide the reCAPTCHA badge because it's hide some elements in the video room UI
  useEffect(() => {
    const grecaptchaEl = document.querySelector('.grecaptcha-badge') as HTMLDivElement;
    if (grecaptchaEl) {
      grecaptchaEl.style.visibility = 'hidden';
    }
  }, []);

  useInitChat();

  useInitForum();

  useEffect(() => {
    if (sessionStorage.getItem('show_tutorial')) {
      setTutorial(true);
    }
  }, [authenticatedUser]);

  const routes = AppBarRoutes.filter((route) => route.allowedRoles.some((role) => authenticatedUser.roles.includes(role)));

  return (
    <>
      {!authenticatedUser.verified ? (
        <Navigate to='validation' />
      ) : (
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <TutorialDialog open={tutorial} onClose={() => handleTutorialClose('')} />
          <CssBaseline />
          <AppBar routes={routes} onDrawerToggle={handleLeftDrawerToggle} />
          <SideDrawer routes={routes} mobileOpen={mobileOpen} onDrawerToggle={handleLeftDrawerToggle} />
          <Box width='100%' minHeight='94vh'>
            <Toolbar />
            <Main open={isOnboardingOpen}>
              <Outlet />
            </Main>
            {isOnboardingSideDrawerFeatureEnabled && (
              <>
                <Tooltip arrow title='How to use SalesHookup' aria-label='How to use SalesHookup'>
                  <Fab id='onboarding-side-drawer-button' color='info' sx={{ position: 'fixed', bottom: 30, right: 50 }} onClick={openOnboarding}>
                    <VideoLibraryIcon />
                  </Fab>
                </Tooltip>
                {isOnboardingOpen && <OnboardingSideDrawer drawerWidth={onboardingDrawerWidth} open={isOnboardingOpen} onClose={closeOnboarding} />}
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
