import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  forumGetPostRawContent,
  forumGetPostReplies,
  forumGetRecentTopics,
  forumGetTopicData,
  forumGetUserProfile,
  forumGetUserSettings,
  forumListCategories,
  getMe
} from '../query-fn';

export const ForumQueryStore = createQueryKeys('forum', {
  me: {
    queryKey: null,
    queryFn: (ctx) => getMe(ctx)
  },
  'recent-topics': {
    queryKey: null,
    queryFn: (ctx) => forumGetRecentTopics(ctx)
  },
  'list-categories': {
    queryKey: null,
    queryFn: (ctx) => forumListCategories(ctx)
  },
  'get-post-replies': (pid: number) => ({
    queryKey: [pid],
    queryFn: (ctx) => forumGetPostReplies(pid, ctx)
  }),
  'get-post-raw-content': (pid: number) => ({
    queryKey: [pid],
    queryFn: (ctx) => forumGetPostRawContent(pid, ctx)
  }),
  'get-topic-data': (tid: number) => ({
    queryKey: [tid],
    queryFn: (ctx) => forumGetTopicData(tid, ctx)
  }),
  'user-settings': (userSlug: string) => ({
    queryKey: [userSlug],
    queryFn: (ctx) => forumGetUserSettings(userSlug, ctx)
  }),
  'user-profile': (userSlug: string) => ({
    queryKey: [userSlug],
    queryFn: (ctx) => forumGetUserProfile(userSlug, ctx)
  })
});
