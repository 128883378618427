import MUIAccordion, { AccordionProps as MUIAccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

export type AccordionProps = MUIAccordionProps;

export const Accordion = styled(MUIAccordion)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: '0 0 0 0'
}));
