import { styled } from '@mui/material';
import MUIPaper, { PaperProps as MUIPaperProps } from '@mui/material/Paper';

export type PaperProps<C extends React.ElementType = React.ElementType> = MUIPaperProps<C, { component?: C }>;

const StyledPaper = styled(MUIPaper)<PaperProps>(() => ({}));

export function Paper({ variant = 'outlined', ...props }: PaperProps) {
  return <StyledPaper variant={variant} {...props} />;
}
