import * as z from 'zod';

import { PasswordValidationSchema, UserNameValidationSchema } from './common';

export const ForgotPasswordValidationSchema = z.object({
  username: UserNameValidationSchema
});

export type ForgotPasswordValidationSchemaType = z.infer<typeof ForgotPasswordValidationSchema>;

export const ResetPasswordValidationSchemaObject = z.object({
  password: PasswordValidationSchema,
  confirmPassword: PasswordValidationSchema
});

export const ResetPasswordValidationSchema = ResetPasswordValidationSchemaObject.refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ['confirmPassword']
});

export type ResetPasswordValidationSchemaType = z.infer<typeof ResetPasswordValidationSchema>;
