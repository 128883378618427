import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { ErrorPage } from '@components/ErrorPage';
import { GuestUserPageContainer } from '@components/GuestUserPageContainer';
import { NotFoundPage } from '@components/NotFoundPage';

interface ErrorResponseImpl {
  status: number;
  statusText?: string;
  internal: boolean;
  data: string;
  error: Record<string, unknown>;
  message?: string;
}

const DynamicImportErrors = ['Unable to preload CSS', 'Failed to fetch dynamically imported module', 'Importing a module script failed'];

export function RootBoundary() {
  const routeError = useRouteError() as ErrorResponseImpl;
  const hasDynamicImportError = DynamicImportErrors.some((v) => routeError.statusText?.includes(v) || routeError.message?.includes(v));

  useEffect(() => {
    console.log(routeError);
    if (routeError.statusText !== 'Not Found' && !hasDynamicImportError) {
      captureException(routeError, { level: 'fatal' });
    }
  }, [routeError, hasDynamicImportError]);

  // When the error is due to a failed dynamic import, we don't want to show the error page
  // because we reload the page
  if (hasDynamicImportError) {
    window.location.reload();

    return null;
  }

  return <GuestUserPageContainer>{routeError.statusText === 'Not Found' ? <NotFoundPage /> : <ErrorPage />}</GuestUserPageContainer>;
}
