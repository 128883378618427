import * as Sentry from '@sentry/react';

export const SentryConfiguration: Sentry.BrowserOptions = {
  release: 'saleshookup-client@' + import.meta.env.PACKAGE_VERSION,
  dsn: 'https://2253da5eadb39a7b6df0a17373424025@o4506264944050176.ingest.sentry.io/4506264947654656',
  enabled: !!import.meta.env.VITE_ENV,
  environment: import.meta.env.VITE_ENV,
  ...(import.meta.env.VITE_ENV === 'prod'
    ? {
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0
      }
    : {}),
  integrations: [
    ...(import.meta.env.VITE_ENV === 'prod'
      ? [
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
          })
        ]
      : []),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['saleshookup-client-application-key'],
      behaviour: 'drop-error-if-contains-third-party-frames'
    })
  ]
};
