import { forwardRef, RefObject } from 'react';
import MUITextField, { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from '@mui/material/TextField';

export type TextFieldProps<Variant extends TextFieldVariants = TextFieldVariants> = Variant extends 'filled'
  ? FilledTextFieldProps
  : Variant extends 'standard'
    ? StandardTextFieldProps
    : OutlinedTextFieldProps;

export const TextField = forwardRef<TextFieldProps['ref'], TextFieldProps>((props, ref) => {
  return <MUITextField ref={ref as unknown as RefObject<HTMLDivElement>} {...props} />;
});

TextField.displayName = 'TextField';
