import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getTags } from '../query-fn';

export const TagQueryStore = createQueryKeys('tag', {
  all: {
    queryKey: null,
    queryFn: (ctx) => getTags(ctx)
  }
});
