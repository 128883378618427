import { createQueryKeys } from '@lukemorales/query-key-factory';

import { QueryStringParamsType } from '../queries';
import {
  disableUser,
  getAuthenticatedUser,
  getAuthUserMailingPreferences,
  getAuthUserTotalHookups,
  getTopActiveMembers,
  getUserDetailByUsernameOrFullName,
  getUserOrganizations,
  getUsersPaginated
} from '../query-fn';

export const UserQueryStore = createQueryKeys('user', {
  authenticated: {
    queryKey: null,
    queryFn: (ctx) => getAuthenticatedUser(ctx)
  },
  'auth-user-total-hookups': {
    queryKey: null,
    queryFn: (ctx) => getAuthUserTotalHookups(ctx)
  },
  'auth-user-top-active-members': {
    queryKey: null,
    queryFn: (ctx) => getTopActiveMembers(ctx)
  },
  'auth-user-mailing-preferences': {
    queryKey: null,
    queryFn: (ctx) => getAuthUserMailingPreferences(ctx)
  },
  'details-by-username-or-fullname': (key: string) => ({
    queryKey: [key],
    queryFn: (ctx) => getUserDetailByUsernameOrFullName({ ...ctx, params: { key } })
  }),
  paginated: (params: QueryStringParamsType<{ f_fullName: string | undefined; f_username?: string }>) => ({
    queryKey: [params],
    queryFn: (ctx) => getUsersPaginated(params, ctx)
  }),
  organizations: {
    queryKey: null,
    queryFn: (ctx) => getUserOrganizations(ctx)
  },
  disable: (uuid: string) => ({
    queryKey: [uuid],
    queryFn: (ctx) => disableUser(uuid, ctx)
  })
});
