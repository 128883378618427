import { Alert, Button, ButtonProps, Divider, SectionContainerPropsType, Stack } from '..';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '.';

export type CommonDialogProps = {
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  title: string;
  open: boolean;
  children: React.ReactNode;
  saveText?: string;
  saveIcon?: React.ReactNode;
  cancelText?: string;
  saveButtonColor?: ButtonProps['color'];
  loading?: boolean;
  fullWidth?: DialogProps['fullWidth'];
  maxWidth?: DialogProps['maxWidth'];
  noActions?: boolean;
  info?: React.ReactNode;
  success?: React.ReactNode;
  error?: React.ReactNode;
  alertObject?: SectionContainerPropsType['alert'];
  onClose?: () => void;
  onSave?: () => void;
};

export function CommonDialog(props: Readonly<CommonDialogProps>) {
  return (
    <Dialog
      aria-labelledby={props.ariaLabelledBy}
      aria-describedby={props.ariaDescribedBy}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle id={props.ariaLabelledBy} onClickCloseIcon={props.onClose}>
        {props.title}
      </DialogTitle>
      <Divider component='div' />
      {props.alertObject && (
        <Alert title={props.alertObject.title} severity={props.alertObject.severity}>
          {props.alertObject.message}
        </Alert>
      )}
      {!!props.info && (
        <Alert severity='info' sx={{ width: '100%', borderRadius: 0 }}>
          {props.info}
        </Alert>
      )}
      {!!props.success && !props.error && (
        <Alert severity='success' sx={{ width: '100%', borderRadius: 0 }}>
          {props.success}
        </Alert>
      )}
      {!!props.error && !props.success && (
        <Alert severity='error' sx={{ width: '100%', borderRadius: 0 }}>
          {props.error}
        </Alert>
      )}
      <DialogContent id={props.ariaDescribedBy}>{props.children}</DialogContent>
      <Divider component='div' />
      {!props.noActions && (
        <DialogActions>
          <Stack direction='row' spacing={2}>
            <Button variant='contained' startIcon={props.saveIcon} color={props.saveButtonColor ?? 'primary'} loading={props.loading} onClick={props.onSave}>
              {props.saveText ?? 'Save'}
            </Button>
            <Button disabled={props.loading} variant='text' onClick={props.onClose}>
              {props.cancelText ?? 'Cancel'}
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
