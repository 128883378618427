import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Grid } from '@tulp';

import './guest-user-app-container.css';

export type GuestUserAppOutletContextType = [string, (background: string) => void];

export function GuestUserAppContainer() {
  const [background, setBackground] = useState();

  return (
    <Grid container component='main' className='guest-page'>
      <Grid xs={false} sm={false} md={6} lg={5} xl={5} style={{ backgroundImage: `url(${background})` }} className='image' />
      <Grid xs={12} sm={12} md={6} lg={7} xl={7} className='content'>
        <Outlet context={[background, setBackground]} />
      </Grid>
    </Grid>
  );
}
