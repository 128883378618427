import { ConnectionState } from '@twilio/conversations';

import { ActionType } from '../action-types';
import { Action } from '../actions';

const reducer = (state: ConnectionState = 'unknown', action: Action): ConnectionState => {
  switch (action.type) {
    case ActionType.UPDATE_CONNECTION_STATE:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
