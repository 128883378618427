import { Client } from '@twilio/conversations';

import { ActionType } from '../action-types';
import { Action } from '../actions';

const reducer = (state = null, action: Action): Client | null => {
  switch (action.type) {
    case ActionType.UPDATE_CLIENT_CHAT:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
