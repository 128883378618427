import { QueryFunctionContext } from '@tanstack/react-query';

import { ForumApiV3ListResponseDataType, ForumCategoryDataType } from '@query';

import { forumApiV3Request } from '.';

export async function forumListCategories(params: QueryFunctionContext) {
  const res = await forumApiV3Request.get('/categories', params);
  return res.data as ForumApiV3ListResponseDataType<ForumCategoryDataType, 'categories'>;
}
