import { cloneElement, forwardRef, Fragment, useState } from 'react';

import { AngleIcon, AngleIconPropsType, Button, ButtonProps, mapReactChildren, Menu, MenuProps } from '@tulp';

type ButtonRef = HTMLButtonElement;

type DropdownButtonProps = ButtonProps & {
  title: React.ReactNode;
  angleDirection?: AngleIconPropsType['direction'];
  useHover?: boolean;
  menuProps?: MenuProps;
};

type DropdownMenuItemProps = ButtonProps & {
  noClose?: boolean;
};

export const DropdownButton = forwardRef<ButtonRef, DropdownButtonProps>(function DropdownButton(props, ref) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick: ButtonProps['onClick'] = (event) => {
    setAnchorEl(event.currentTarget);
    props.onClick?.(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        {...props}
        ref={ref}
        sx={{ fontWeight: 600 }}
        endIcon={<AngleIcon direction={props.angleDirection || 'down'} />}
        onMouseOver={props.useHover ? handleClick : () => null}
        onClick={props.useHover ? () => null : handleClick}
      >
        {props.title}
      </Button>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 9999 }}
        MenuListProps={{ onMouseLeave: props.useHover ? handleClose : () => undefined }}
        PaperProps={{
          sx: {
            mt: 0.5,
            zIndex: 9999
          }
        }}
        {...props.menuProps}
      >
        {mapReactChildren<DropdownMenuItemProps>(props.children, (child) =>
          cloneElement(child, {
            onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
              if (!child.props.noClose) {
                handleClose();
              }
              child.props.onClick?.(event);
            }
          })
        )}
      </Menu>
    </Fragment>
  );
});
