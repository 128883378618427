import { QueryFunctionContext } from '@tanstack/react-query';

import {
  forumApiRequest,
  forumApiV3Request,
  ForumApiV3SimpleResponseDataType,
  ForumCreateNewTopicPayloadType,
  ForumCreateTopicResponseType,
  ForumReplyToTopicPayloadType,
  ForumReplyToTopicResponseType,
  ForumTopicDataType
} from '@query';
import { UnknownObject } from '@tulp';

export async function forumCreateNewTopic(data: ForumCreateNewTopicPayloadType) {
  const res = await forumApiV3Request.post('/topics', data);
  return res.data as ForumCreateTopicResponseType;
}

export async function forumGetRecentTopics(params: QueryFunctionContext) {
  const res = await forumApiRequest.get('/recent', params);
  return res.data;
}

export async function forumGetTopicData(tid: number, params?: QueryFunctionContext) {
  const res = await forumApiRequest.get(`/topic/${tid}`, params);
  return res.data as ForumTopicDataType;
}

export async function forumReplyToTopic({ tid, toPid, content }: ForumReplyToTopicPayloadType) {
  const res = await forumApiV3Request.post(`/topics/${tid}`, { content, toPid });
  return res.data as ForumReplyToTopicResponseType;
}

export async function forumFollowTopic(tid: number) {
  const res = await forumApiV3Request.put(`/topics/${tid}/follow`);
  return res.data as ForumApiV3SimpleResponseDataType<UnknownObject>;
}
