import ReactDOM from 'react-dom/client';

import './index.css';
import App from './app';

async function enableMocking() {
  if (['stg', 'prod'].includes(import.meta.env.VITE_ENV)) {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
enableMocking().then(() => {
  root.render(<App />);
});
