import { request } from '@request';

import {
  QueryParamsWithSignal,
  VideoSdkCreateRoomBodyParamsType,
  VideoSdkGenerateTokenQueryParamsType,
  VideoSdkGenerateTokenResponseType,
  VideoSdkRoomType,
  VideoSdkValidateRoomQueryParamsType
} from '../types';
import { attachQueryParams } from '../utils';
import { URL_API_BASE, VIDEOSDK_URL_API_BASE } from '.';

export async function videoSdkGenerateToken(args?: QueryParamsWithSignal<VideoSdkGenerateTokenQueryParamsType>) {
  const res = await request.get(attachQueryParams(`${URL_API_BASE}/video-sdk/generate-token`, args?.params), { signal: args?.signal });
  return res.data as VideoSdkGenerateTokenResponseType;
}

export async function videoSdkCreateRoom(bodyParams: VideoSdkCreateRoomBodyParamsType) {
  const res = await request.post(`${URL_API_BASE}/video-sdk/create-room`, { data: bodyParams });
  return res.data as VideoSdkRoomType;
}

export async function videoSdkValidateRoom({ roomId, token }: VideoSdkValidateRoomQueryParamsType) {
  const url = `${VIDEOSDK_URL_API_BASE}/v2/rooms/validate/${roomId}`;

  const options = {
    method: 'GET',
    headers: { Authorization: token, 'Content-Type': 'application/json' }
  };

  const response = await fetch(url, options);

  if (response.status === 400) {
    const data = await response.text();
    return { data: null, err: data };
  }

  const data = await response.json();

  if (data.roomId) {
    return { data: data as VideoSdkRoomType, err: null };
  } else {
    return { data: null, err: data.error };
  }
}
