import { cloneElement } from 'react';
import MUIAlert, { AlertProps as MUIAlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

import { ButtonProps, mapReactChildren } from '@tulp';

import { Stack } from '..';
import { AlertTitle } from './alert-title';

export interface AlertProps extends MUIAlertProps {}
const StyledAlert = styled(MUIAlert)<AlertProps>(() => ({
  borderRadius: 8,
  '& .MuiAlert-message': {
    textAlign: 'justify',
    width: '100%'
  }
}));

export function Alert({ title, children, action, ...props }: AlertProps) {
  if (action) {
    return (
      <StyledAlert sx={{ color: 'black' }} {...props}>
        <Stack direction='column' spacing={1}>
          <div>
            {!!title && (
              <AlertTitle>
                <b>{title}</b>
              </AlertTitle>
            )}
            {children}
          </div>
          <Stack direction='row' spacing={1} sx={{ position: 'relative', left: '-6px' }}>
            {mapReactChildren<ButtonProps>(action, (button) =>
              cloneElement(button, {
                size: button.props.size || 'small',
                color: button.props.color || props.severity,
                variant: button.props.variant || 'text'
              })
            )}
          </Stack>
        </Stack>
      </StyledAlert>
    );
  }
  return (
    <StyledAlert {...props}>
      {!!title && (
        <AlertTitle>
          <b>{title}</b>
        </AlertTitle>
      )}
      {children}
    </StyledAlert>
  );
}
