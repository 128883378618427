import MUITab, { TabProps as MUITabProps } from '@mui/material/Tab';

const a11yProps = (identifier: string) => ({
  id: `${identifier}-tab`,
  'aria-controls': `${identifier}-tabpanel`
});

export interface TabProps extends MUITabProps {
  // force use of label
  label: string;
}

export const Tab = (props: TabProps) => <MUITab {...props} {...a11yProps(props.label.toString().replace(/\s/g, ''))} />;
