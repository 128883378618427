import { UserFormValidationSchemaType } from '@form';
import { request } from '@request';
import { UnknownObject } from '@tulp';

import { BASE_URL } from '../utils/consts';

export function login(data: UnknownObject) {
  return request.post(`${BASE_URL}/auth/login`, { data });
}

export function resetPassword(data: UnknownObject) {
  return request.put(`${BASE_URL}/password/reset/confirm`, { data });
}

export function unsubscribe(data: UnknownObject) {
  return request.put(`${BASE_URL}/unsubscribe?token=${data.token}&email=${data.email}`, { data });
}

export function register(data: UnknownObject) {
  return request.post(`${BASE_URL}/auth/register`, { data });
}

export function updateAsUser(userId: string, data: UserFormValidationSchemaType & { id?: string }) {
  return request.put(`${BASE_URL}/user`, { data: { ...data, id: userId } });
}

export function updateAsAdmin(userId: string, data: UserFormValidationSchemaType) {
  return request.put(`${BASE_URL}/admin/user/edit/${userId}`, { data: { ...data, id: userId } });
}

export function create(data: UnknownObject) {
  return request.post(`${BASE_URL}/admin/user`, { data });
}

export function resendEmail(data: UnknownObject) {
  return request.post(`${BASE_URL}/user/resend-email`, { data });
}

export function verifyUser(params: { token: string }) {
  return request.get(`${BASE_URL}/auth/confirm?token=${params.token}`);
}

export function verifyUserEmail(data: UnknownObject) {
  return request.put(`${BASE_URL}/user/username/confirm`, { data });
}

export function uploadPhoto(data: UnknownObject) {
  return request.post(`${BASE_URL}/user/upload-avatar`, {
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function deletePhoto() {
  return request.delete(`${BASE_URL}/user/delete-avatar`);
}

export function updateUserPassword(data: UnknownObject) {
  return request.put(`${BASE_URL}/user/password`, { data });
}

export function updateUserEmail(data: UnknownObject) {
  return request.post(`${BASE_URL}/user/username/change`, { data });
}

export function linkGoogleCalendar(data: UnknownObject) {
  return request.put(`${BASE_URL}/user/google-calendar`, { data });
}

export function inviteFriends(data: UnknownObject) {
  return request.post(`${BASE_URL}/referrals`, { data });
}

export function leaveReview(data: UnknownObject) {
  return request.post(`${BASE_URL}/reviews`, { data });
}
