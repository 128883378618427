import { forwardRef } from 'react';
import { LinkProps } from 'react-router-dom';
import { Button as MUIButtonUnstyled } from '@mui/base/Button';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button';

export interface ButtonProps extends MUIButtonProps {
  to?: LinkProps['to'];
  state?: LinkProps['state'];
  selected?: boolean;
  loading?: boolean;
  loadingPosition?: 'start' | 'end';
}
type ButtonRef = HTMLButtonElement;

const StyledButton = styled(MUIButton)<ButtonProps>(({ theme, variant, color = 'primary', selected, sx }) => () => {
  const getButtonBackgroundColor = () => {
    if (variant == 'outlined') {
      return theme.palette[color]?.light;
    } else if (variant == 'text') {
      return 'default';
    } else {
      return theme.palette[color].dark;
    }
  };
  return {
    minWidth: 'max-content',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      color: variant == 'contained' ? theme.palette[color].contrastText : theme.palette[color].main,
      backgroundColor: getButtonBackgroundColor
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem #8AD7F1',
      borderColor: theme.palette[color].main,
      outline: 'none',
      textDecoration: 'none'
    },
    ...(selected && {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      backgroundColor: variant == 'text' ? sx?.(theme)['&:active']?.backgroundColor || theme.palette.primary.light : getButtonBackgroundColor(),
      color: theme.palette[color].contrastText
    })
  };
});

export const Button = forwardRef<ButtonRef, ButtonProps>(({ loading, loadingPosition = 'start', ...props }, ref) => {
  if (loading) {
    return (
      <LoadingButton
        {...props}
        ref={ref}
        loading={loading}
        startIcon={loading ? <SaveIcon /> || props.startIcon : props.startIcon} // Required to apply logic for LoadingButton
        loadingPosition={loadingPosition}
      />
    );
  }
  return <StyledButton {...props} ref={ref} />;
});

Button.displayName = 'Button';

export const ButtonUnstyled = MUIButtonUnstyled;
