import { Link, useLocation } from 'react-router-dom';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { NavigationRoute } from '@routes';

import { useGetAuthenticatedUser } from '@query';
import { ROLES_MAP } from '@utils/permissions';
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, NestedListItem, Toolbar, useIsButtonLinkActive } from '@tulp';

const drawerWidth = 310;

export type SideDrawerProps = {
  routes: NavigationRoute[];
  mobileOpen: boolean;
  onDrawerToggle: () => void;
};

export function SideDrawer(props: Readonly<SideDrawerProps>) {
  const location = useLocation();
  const checkActive = useIsButtonLinkActive();

  const { data: authenticatedUser } = useGetAuthenticatedUser();

  const isAdminUser = authenticatedUser?.roles.includes(ROLES_MAP.admin);

  const DrawerContent = (
    <>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {props.routes.map((route, key) =>
            route.children ? (
              <NestedListItem key={key} mainIcon={route.icon} mainText={route.label} mainItemSx={{ pl: 10 }}>
                {route.children
                  .filter((route) => route.allowedRoles.some((role) => authenticatedUser.roles.includes(role)))
                  .map((route, key) => (
                    <ListItemButton
                      key={key}
                      sx={{ pl: 20 }}
                      selected={checkActive(route.to ?? '')}
                      component={Link}
                      to={route.to ?? ''}
                      onClick={props.mobileOpen ? props.onDrawerToggle : () => null}
                    >
                      <ListItemIcon>{route.icon}</ListItemIcon>
                      <ListItemText primary={route.label} />
                    </ListItemButton>
                  ))}
              </NestedListItem>
            ) : (
              <ListItem key={key} disablePadding>
                <ListItemButton
                  sx={{ pl: 10 }}
                  selected={checkActive(route.to ?? '')}
                  component={Link}
                  to={route.to ?? ''}
                  onClick={props.mobileOpen ? props.onDrawerToggle : () => null}
                >
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.label} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        <Divider />
        <List>
          {isAdminUser ? (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 10 }}
                selected={location.pathname.includes('invite')}
                component={Link}
                to='/invite'
                onClick={props.mobileOpen ? props.onDrawerToggle : () => null}
              >
                <ListItemIcon>
                  <ShareRoundedIcon />
                </ListItemIcon>
                <ListItemText primary='Invite Friends' />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem disablePadding>
              <ListItemButton sx={{ pl: 10 }} href='mailto:hello@saleshookup.com' onClick={props.mobileOpen ? props.onDrawerToggle : () => null}>
                <ListItemIcon>
                  <MarkEmailReadOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Contact us' />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
    </>
  );

  return (
    <Drawer
      variant='temporary'
      open={props.mobileOpen}
      sx={{
        display: { xs: 'block', lg: 'none' },
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      onClose={props.onDrawerToggle}
    >
      {DrawerContent}
    </Drawer>
  );
}
