import { useEffect } from 'react';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { forumApiV3Request, forumLogin, forumRequest, getForumSettings } from '@query';

export function useInitForum() {
  useEffect(() => {
    forumLogin();

    const forumApiV3Interceptor = forumApiV3Request.interceptors.response.use(
      (config) => {
        return config;
      },
      async (error: AxiosError) => {
        const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };
        if (!originalRequest?._retry && error.response?.status === 403) {
          originalRequest._retry = true;

          const apiSettingsData = await getForumSettings();
          forumApiV3Request.defaults.headers.common['X-Csrf-Token'] = apiSettingsData.csrf_token;
          originalRequest.headers['X-Csrf-Token'] = apiSettingsData.csrf_token;

          return forumApiV3Request(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    const forumRequestInterceptor = forumRequest.interceptors.response.use(
      (config) => {
        return config;
      },
      async (error: AxiosError) => {
        const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };
        if (!originalRequest?._retry && error.response?.status === 403) {
          originalRequest._retry = true;

          const apiSettingsData = await getForumSettings();
          forumRequest.defaults.headers.common['X-Csrf-Token'] = apiSettingsData.csrf_token;
          originalRequest.headers['X-Csrf-Token'] = apiSettingsData.csrf_token;

          return forumRequest(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      forumApiV3Request.interceptors.response.eject(forumApiV3Interceptor);
      forumRequest.interceptors.response.eject(forumRequestInterceptor);
    };
  }, []);
}
