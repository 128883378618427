import { forwardRef, Fragment, useEffect, useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { CircularProgress } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { UserDataType, UserQueryStore } from '@query';
import { Autocomplete, Box, Grid, TextField, Typography, useData, useDebounce } from '@tulp';
import { UserSimplePaginatedTableParamsType } from '@table';

export type UserSelectValueType = UserDataType & {
  label: string;
  value: string;
};

export type UserSelectProps = {
  required?: boolean;
  value?: string | null;
  size?: 'small' | 'medium';
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  label?: React.ReactNode;
  onChange: (v: UserSelectValueType | null) => void;
};

export const UserSelect = forwardRef((props: UserSelectProps, ref) => {
  const [stateFullValue, setStateFullValue] = useState<UserSelectValueType | null>(null);
  const [params, setParams] = useData<UserSimplePaginatedTableParamsType>({
    f_fullName: '',
    pagination: {
      pageIndex: 0,
      pageSize: 50
    }
  });

  const debouncedParams = useDebounce(params, 500);

  const { data, isLoading, isFetching, status } = useQuery({
    ...UserQueryStore.paginated({
      page: debouncedParams.pagination.pageIndex,
      size: debouncedParams.pagination.pageSize,
      f_fullName: debouncedParams.f_fullName,
      f_username: debouncedParams.f_fullName ? '' : props.value
    }),
    placeholderData: keepPreviousData
  });
  const users = data?.elements ?? [];

  function handleChangeValue(value: UserSelectValueType | null) {
    setStateFullValue(value);
    props.onChange(value);
  }

  useEffect(() => {
    if (status === 'success') {
      const userValue = users.find((v) => v.username === props.value);
      const value: UserSelectValueType | null = userValue ? { label: userValue.fullName, value: userValue.username, ...userValue } : null;
      setStateFullValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Autocomplete
      disablePortal
      fullWidth
      ref={ref}
      loading={isFetching || isLoading}
      size={props.size}
      value={stateFullValue}
      inputValue={params.f_fullName}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={(x) => x}
      disabled={props.disabled}
      options={users.map((v) => ({
        label: v.fullName,
        value: v.username,
        ...v
      }))}
      renderInput={(inputParams) => (
        <TextField
          error={props.error}
          {...inputParams}
          InputProps={{
            ...inputParams.InputProps,
            endAdornment: (
              <Fragment>
                {isFetching || isLoading ? <CircularProgress color='inherit' size={30} /> : null}
                {inputParams.InputProps.endAdornment}
              </Fragment>
            )
          }}
          required={props.required}
          helperText={props.helperText}
          label={props.label ?? 'User'}
        />
      )}
      renderOption={(props, option) => {
        const { ...optionProps } = props;

        const matches = match(option.label, params.f_fullName ?? '');
        const parts = parse(option.label, matches);
        return (
          <li {...optionProps} key={option.value}>
            <Grid container sx={{ alignItems: 'stretch' }}>
              <Grid sx={{ display: 'flex', width: 44 }}>
                <LinkedInIcon color={option.loggedWithLinkedIn ? 'primary' : 'disabled'} />
              </Grid>
              <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box key={index} component='span' sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  {option.username}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      onInputChange={(_, newInputValue) => setParams({ f_fullName: newInputValue })}
      onChange={(_, value) => handleChangeValue(value)}
    />
  );
});

UserSelect.displayName = 'UserSelect';
