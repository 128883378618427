import { forwardRef } from 'react';

import { useGetJobPositions } from '@query';
import { jobsTransformString } from '@utils/common';
import { Autocomplete, TextField } from '@tulp';

export type JobPositionSelectProps = {
  required?: boolean;
  value?: string | null;
  size?: 'small' | 'medium';
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  onChange: (v: { label: string; value: string } | null) => void;
};

export const JobPositionSelect = forwardRef((props: JobPositionSelectProps, ref) => {
  const { data: jobPositions } = useGetJobPositions();

  return (
    <Autocomplete
      disablePortal
      fullWidth
      disableClearable
      ref={ref}
      size={props.size}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      value={props.value ? { label: jobsTransformString(props.value), value: props.value } : null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disabled={props.disabled}
      options={(jobPositions || []).map((v) => ({
        label: jobsTransformString(v),
        value: v
      }))}
      renderInput={(params) => <TextField error={props.error} required={props.required} helperText={props.helperText} {...params} label='Job position' />}
      onChange={(_, value) => props.onChange(value)}
    />
  );
});

JobPositionSelect.displayName = 'JobPositionSelect';
