import { QueryFunctionContext } from '@tanstack/react-query';

import {
  forumApiRequest,
  forumApiV3Request,
  ForumApiV3SimpleResponseDataType,
  ForumUpdateUserSettingsPayloadType,
  ForumUpdateUserSettingsResType,
  ForumUserSettingsDataType
} from '@query';

export async function forumUpdateUserSettings({ userId, settings }: ForumUpdateUserSettingsPayloadType) {
  const res = await forumApiV3Request.put(`/users/${userId}/settings`, { settings });
  return res.data as ForumApiV3SimpleResponseDataType<ForumUpdateUserSettingsResType>;
}

export async function forumGetUserSettings(userSlug: string, params: QueryFunctionContext) {
  const res = await forumApiRequest.get(`user/${userSlug}/settings`, params);
  return res.data as ForumUserSettingsDataType;
}
