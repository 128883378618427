/* eslint-disable @typescript-eslint/no-explicit-any */
import { equals, isNil } from 'remeda';

import { UnknownObject } from '../type';

export function deRef(object: any, chain: any, defaultValue: any = null) {
  let response = object;
  const chunks = chain.split(':'),
    accessor = chunks[0],
    forceType = chunks.length > 1 ? chunks[1] : null;
  accessor
    .split('.')
    .filter((x: any) => x.length > 0)
    .forEach((property: any) => {
      response = response[property];
    });
  switch (forceType) {
    case 'int':
      response = parseInt(response);
      break;
    case 'float':
      response = parseFloat(response);
      break;
    case 'date':
    case 'datetime':
      response = new Date(response);
      break;
    default:
      break;
  }
  return response || defaultValue;
}

/**
 *
 * Uses a binary search to determine the index of the item
 * in the array that matches the given key.
 */
export function findIndex<T>(sortedList: T[], item: T, idProp: string, from?: number, to?: number): number | null | undefined {
  if (!sortedList.length) {
    return null;
  }
  if (from == null) {
    from = 0;
  }
  if (to == null) {
    to = sortedList.length - 1;
  }
  const mid = Math.trunc((from + to) / 2),
    reference = deRef(item, idProp),
    pretender = deRef(sortedList[mid], idProp);
  if (reference == pretender) {
    return mid;
  } else {
    if (from >= to) {
      return null;
    }
    if (reference < pretender) {
      return findIndex(sortedList, item, idProp, from, Math.max(from, mid - 1));
    }
    if (reference > pretender) {
      return findIndex(sortedList, item, idProp, Math.min(mid + 1, to), to);
    }
  }
}

export function sortData<T>(list: T[], fields: string[]) {
  const fieldData = fields.map((field) => ({
      field: field[0] == '-' ? field.slice(1) : field,
      swap: field[0] == '-'
    })),
    primitiveComparer = (a: T, b: T, swap = false): number => {
      if (swap) {
        return primitiveComparer(b, a);
      }
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    },
    comparer = (a: T, b: T) => {
      if (typeof a === typeof b) {
        const comparisonMap = fieldData.map(({ field, swap }) => primitiveComparer(deRef(a, field), deRef(b, field), swap));
        return comparisonMap.find((x) => x != 0) || 0;
      } else {
        return a === null || a === undefined ? -1 : 1;
      }
    };
  return list.sort(comparer);
}

export function mergeData<T extends UnknownObject>(list: T[], items: T[] | any[], idProp = 'id', prepend = false): T[] {
  let targetIndex: number | null | undefined = null;
  const itemsToAdd: any[] = [];

  let wrappedList = list.map((item, i) => ({
    identity: item[idProp],
    item: item,
    _mdi: i
  }));
  wrappedList = sortData(wrappedList, ['identity']);

  const wrappedItems = items.map((item) => ({
    identity: item[idProp],
    item: item
  }));

  wrappedItems.forEach((wrappedItem) => {
    targetIndex = findIndex(wrappedList, wrappedItem, 'identity');
    if (isNil(targetIndex)) {
      itemsToAdd.push(wrappedItem);
    } else {
      wrappedList[targetIndex] = { ...wrappedList[targetIndex], ...wrappedItem };
    }
  });

  wrappedList = prepend ? [...itemsToAdd, ...sortData(wrappedList, ['_mdi'])] : [...sortData(wrappedList, ['_mdi']), ...itemsToAdd];

  return wrappedList.map((x) => x.item);
}

export function removeDuplicateObjects<T>(list: T[], equalityFunc?: (a: T, b: T) => boolean) {
  const result: T[] = [];

  const isAlreadyThere = (item: T, resultList: T[]) => {
    for (const resultItem of resultList) {
      if (equalityFunc?.(resultItem, item) ?? equals(resultItem, item)) {
        return true;
      }
    }
    return false;
  };

  list.forEach((value) => {
    if (!isAlreadyThere(value, result)) {
      result.push(value);
    }
  });

  return result;
}

// O(nm)
export function removeData<T extends UnknownObject>(list: T[], items: T[], idProp = 'id') {
  const returnList = [];
  const itemKeys = items.map((x) => x[idProp]);
  for (let j = 0; j < list.length; j++) {
    if (itemKeys.indexOf(list[j][idProp]) == -1) {
      returnList.push(list[j]);
    }
  }
  return returnList;
}
