import { styled } from '@mui/material/styles';
import MUITabs, { TabsProps as MUITabsProps } from '@mui/material/Tabs';

import { Box } from '..';

const StyledTabs = styled(MUITabs)(({ theme }) => ({
  minHeight: 'fit-content',
  '& .MuiTabs-flexContainer': {
    gap: theme.spacing(8)
  },
  '& .MuiTabs-indicator': {
    height: theme.typography.pxToRem(4)
  },
  '& .MuiTab-root': {
    color: theme.palette.text.primary,
    padding: '0.5rem 0',
    margin: 0,
    minWidth: 'fit-content',
    minHeight: 'fit-content'
  }
}));

export interface TabsProps extends MUITabsProps {}

export const Tabs = (props: TabsProps) => (
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <StyledTabs {...props} variant='scrollable' scrollButtons='auto'>
      {props.children}
    </StyledTabs>
  </Box>
);
