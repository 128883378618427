import { Control, Controller, ControllerProps, FieldValues } from 'react-hook-form';

import { TextField, TextFieldProps } from '@tulp';

export type TextFormFieldPropsType<TFieldValues extends FieldValues> = Pick<ControllerProps<TFieldValues>, 'name' | 'control'> &
  TextFieldProps & { control: Control<TFieldValues> };

export function TextFormField<TFieldValues extends FieldValues>(props: Readonly<TextFormFieldPropsType<TFieldValues>>) {
  const { name, control, ...textFieldProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState: { isLoading, isSubmitting } }) => (
        <TextField
          {...textFieldProps}
          {...field}
          disabled={textFieldProps.disabled || isLoading || isSubmitting}
          value={field.value ?? ''}
          error={!!error}
          helperText={error?.message ?? props.helperText}
        />
      )}
    />
  );
}
