import { keepPreviousData, QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { merge } from 'remeda';

import { UnknownObject } from '@tulp';

import { URL_API_BASE } from '../query-fn';
import { UserQueryStore } from '../query-store';
import { MailingPreferencesDataType, OrganizationItemListType, TopActiveMemberDataType, UserDataType } from '../types';
import { DEFAULT_QUERY_PARAMS, useInfiniteQuery } from './infinite';
import { QueryStringParamsType } from './types';

export function useGetAuthenticatedUser(options?: QueryObserverOptions<UserDataType>) {
  const queryRes = useQuery({ ...UserQueryStore.authenticated, ...((options ?? {}) as UnknownObject) });
  return { ...queryRes, data: queryRes.data as UserDataType };
}

export function useGetAuthUserTotalHookups(options?: QueryObserverOptions<number>) {
  return useQuery({ ...UserQueryStore['auth-user-total-hookups'], ...((options ?? {}) as UnknownObject) });
}

export function useGetAuthUserTopActiveMembers(options?: QueryObserverOptions<TopActiveMemberDataType[]>) {
  return useQuery({ ...UserQueryStore['auth-user-top-active-members'], ...((options ?? {}) as UnknownObject) });
}

export function useGetAuthUserMailingPreferences(options?: QueryObserverOptions<MailingPreferencesDataType>) {
  return useQuery({ ...UserQueryStore['auth-user-mailing-preferences'], ...((options ?? {}) as UnknownObject) });
}

export function useGetUserDetailsByUsernameOrFullname(key: string, options?: QueryObserverOptions<UserDataType>) {
  return useQuery({ ...UserQueryStore['details-by-username-or-fullname'](key), ...((options ?? {}) as UnknownObject) });
}

const UsersDefaultQueryStringParams = {
  page: DEFAULT_QUERY_PARAMS.page,
  size: DEFAULT_QUERY_PARAMS.size,
  sorting_parameter: 'createdOn',
  sorting_direction: 'desc',
  f_fullName: '',
  f_firstName: '',
  f_lastName: '',
  f_username: '',
  f_city: '',
  f_companyName: '',
  'f_location.name': '',
  f_isSubscribe: undefined,
  f_jobPosition: undefined
};

export type UsersSortingParametersType =
  | keyof Pick<UserDataType, 'fullName' | 'lastName' | 'username' | 'companyName' | 'jobPosition'>
  | 'location.name'
  | 'isSubscribe';

export type UsersQueryParamsType = {
  f_firstName?: string;
  f_lastName?: string;
  f_username?: string;
  'f_location.name'?: string;
  f_companyName?: string;
  f_jobPosition?: string;
  f_tags?: string;
  f_isSubscribe?: boolean;
};

export type UseUsersInfiniteQueryProps = {
  queryStringParams?: QueryStringParamsType<UsersSortingParametersType>;
};

export function useUsersInfiniteQuery(props?: UseUsersInfiniteQueryProps) {
  return useInfiniteQuery<UserDataType, QueryStringParamsType<UsersSortingParametersType> & UsersQueryParamsType>({
    queryKey: ['users', 'infinite'],
    baseUrl: `${URL_API_BASE}/admin/users`,
    defaultQueryStringParams: merge(UsersDefaultQueryStringParams, props?.queryStringParams),
    queryOptions: {
      placeholderData: keepPreviousData
    }
  });
}

export function useGetUserOrganizations(options?: QueryObserverOptions<OrganizationItemListType[]>) {
  return useQuery({ ...UserQueryStore['organizations'], ...((options ?? {}) as UnknownObject) });
}
