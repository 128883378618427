import { RouteObject } from 'react-router-dom';

import ValidationPage from '@pages/validation-page';
import LinkedIn from '@components/Linkedin';
import Verification from '@components/Verification';
import VerificationEmail from '@components/VerificationEmail';

export const ValidationUserRoutes: RouteObject[] = [
  {
    path: 'user/confirm',
    element: <Verification />
  },
  {
    path: 'user/confirm-changed-email',
    element: <VerificationEmail />
  },
  {
    path: 'validation',
    element: <ValidationPage />
  },
  {
    path: 'linkedIn',
    element: <LinkedIn />,
    children: [
      {
        path: 'login',
        element: <></>
      },
      {
        path: 'sign_up',
        element: <></>
      }
    ]
  }
];
