import { CSSProperties } from 'react';
import { responsiveFontSizes, styled } from '@mui/material';
import MUITypography, { TypographyProps as MUITypographyProps } from '@mui/material/Typography';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    variants: 'title' | 'subtitle' | 'body-bold' | 'body' | 'body4' | 'subtitle3' | 'subtitle4' | 'subtitle5' | 'subtitle6';
    title: true;
    subtitle: true;
    'body-bold': true;
    body: true;
    body4: true;
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    subtitle6: true;
  }
}

export interface TypographyProps extends MUITypographyProps {}

const StyledTypography = styled(MUITypography)<TypographyProps>(({ theme, variant }) => {
  //TODO: variant !== 'subtitle4' is a temporary fix for the variant not being recognized by the MUI theme
  if (variant && !['inherit', 'body4', 'subtitle3', 'subtitle4', 'subtitle5', 'subtitle6'].includes(variant)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const responsiveFontSizeStyle = responsiveFontSizes(theme).typography[variant] as CSSProperties;
    const newStyles = { ...responsiveFontSizeStyle };
    const muiFontSize = newStyles.fontSize;
    // Tweaked base font-size to fit with the Figma design
    if (variant == 'caption' && muiFontSize) {
      newStyles.fontSize = `${(typeof muiFontSize == 'string' ? parseFloat(muiFontSize) : muiFontSize) + 0.15}rem`;
    }
    return newStyles;
  }
});

export function Typography({ variant = 'body1', ...props }: TypographyProps) {
  return <StyledTypography variant={variant} {...props} />;
}
