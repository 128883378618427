import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import BellIcon from '@mui/icons-material/NotificationsNoneRounded';
import { NavigationRoute } from '@routes';
import { useQueryClient } from '@tanstack/react-query';
import { useGate } from 'statsig-react';

import { AppState } from '@chat-app/store';

import { getNotifications, setAsOld, setAsViewed } from '@services/notificationsService';
import { NotificationDataType, useGetAuthenticatedUser } from '@query';
import { logoutCallback } from '@utils/auth';
import { NotificationLink } from '@utils/consts/olds';
import {
  AppBar as TulpAppBar,
  Avatar,
  Badge,
  Box,
  ButtonLink,
  Container,
  DropdownButton,
  formatDate,
  formatTime,
  IconButton,
  IconButtonLink,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuIcon,
  MenuItem,
  Paper,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useIsButtonLinkActive,
  useMediaQuery,
  useTheme
} from '@tulp';

import logo from '../../images/app_bar_logo.png';
import notificationImage from '../../images/default_notification.png';
import smallLogo from '../../images/small_logo.svg';

const profileMenu = [
  {
    label: 'My Profile',
    to: '/profile'
  },
  {
    label: 'Settings',
    to: '/settings'
  }
];

export type AppBarProps = {
  routes: NavigationRoute[];
  onDrawerToggle: () => void;
};

export function AppBar(props: Readonly<AppBarProps>) {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [notificationsPages, setNotificationsPages] = useState(0);
  const [notificationsPage, setNotificationsPage] = useState<number | undefined>(0);
  const [notifications, setNotifications] = useState<NotificationDataType[]>([]);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { value: isFullCalendarFeatureEnabled } = useGate('full_calendar');

  const checkActive = useIsButtonLinkActive();

  const theme = useTheme();
  const mediaQueryUpSm = useMediaQuery(theme.breakpoints.up('md'));

  const unreadMessages = useSelector((state: AppState) => state.unreadMessages);
  const unreadMessagesCount = Object.values(unreadMessages ?? []).reduce((a, b) => a + b, 0);

  const queryClient = useQueryClient();
  const { data: authenticatedUser } = useGetAuthenticatedUser();

  function handleOpenUserMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorElUser(event.currentTarget);
  }

  function handleCloseUserMenu() {
    setAnchorElUser(null);
  }

  function handleOpenNotificationMenu() {
    setAsOld()
      .then(() => {
        setNotificationsCount(0);
      })
      .catch(() => {});
  }

  function getNotificationsList(pageNumber?: number) {
    setNotificationsPage(pageNumber);
    getNotifications({ page: pageNumber })
      .then((result) => {
        setNotifications([...notifications, ...result.data.notifications.elements]);
        setNotificationsCount(result.data.newNotificationsCount);
        setNotificationsPages(result.data.notifications.totalPages);
      })
      .catch(() => {
        // TODO: Bad practice, we should handle this error
      });
  }

  function markAsViewed(notification: NotificationDataType) {
    return setAsViewed({ notificationId: notification.id })
      .then(() => {
        notifications.map((notif) => {
          if (notif.date === notification.date) notif.viewed = true;
          return notif;
        });
        setNotifications([...notifications]);
      })
      .catch(() => {});
  }

  function clickToNavigate(link: string) {
    if (link) window.location.href = link;
  }

  async function handleLogout() {
    logoutCallback(queryClient, '/login');
  }

  // TODO: Refactor this to use RQ
  // Polling for notifications
  // User refetchInterval to poll for notifications
  useEffect(() => {
    getNotificationsList();
    const interval = setInterval(() => {
      getNotificationsList();
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unreadMessagesCount > 0) {
      if (document.title.includes('(')) {
        document.title = `(${unreadMessagesCount}) ${document.title.replace(/\(\d+\)\s*/, '')}`;
      } else {
        document.title = `(${unreadMessagesCount}) ${document.title}`;
      }
    }
  }, [unreadMessagesCount]);

  return (
    <>
      <TulpAppBar variant='outlined' elevation={0} position='fixed' color='inherit' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Container maxWidth='lg'>
          <Toolbar disableGutters>
            <IconButton color='inherit' aria-label='open drawer' edge='start' sx={{ mr: 2, display: { md: 'none' } }} onClick={props.onDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Box display='flex' alignItems='center' flexGrow={1} component={Link} to='/home'>
              <Box component='img' sx={{ width: 200, display: { xs: 'none', sm: 'initial' } }} src={logo} alt='SalesHookup logo' />
              <Box component='img' sx={{ display: { xs: 'initial', sm: 'none' } }} src={smallLogo} alt='SalesHookup logo' />
            </Box>
            <Stack direction='row' spacing={{ xs: 0, md: 3 }}>
              {mediaQueryUpSm && (
                <>
                  {props.routes.map((route) =>
                    route.children ? (
                      <DropdownButton key={route.id} title={route.label}>
                        {route.children
                          .filter((route) => route.allowedRoles.some((role) => authenticatedUser.roles.includes(role)))
                          .map((child) => (
                            <MenuItem selected={checkActive(child?.to?.toString() ?? '')} key={child.id} component={Link} to={child.to ?? ''}>
                              {child.label}
                            </MenuItem>
                          ))}
                      </DropdownButton>
                    ) : route.label ? (
                      <ButtonLink key={route.id} to={route.to ?? ''}>
                        <Typography variant='subtitle4'>{route.label}</Typography>
                      </ButtonLink>
                    ) : null
                  )}
                </>
              )}
              {isFullCalendarFeatureEnabled && (
                <IconButtonLink to='/calendar'>
                  <CalendarMonthRoundedIcon />
                </IconButtonLink>
              )}
              <IconButtonLink to='/chat'>
                <Badge badgeContent={unreadMessagesCount}>{<ChatIcon />}</Badge>
              </IconButtonLink>
              <Popover>
                <PopoverTrigger asChild onClick={handleOpenNotificationMenu}>
                  <IconButton>
                    <Badge badgeContent={notificationsCount} color='info'>
                      <BellIcon />
                    </Badge>
                  </IconButton>
                </PopoverTrigger>
                <PopoverContent>
                  <Paper>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={(page) => {
                        if (notificationsPages < (notificationsPage ?? 0)) getNotificationsList(page);
                      }}
                      hasMore={true}
                      useWindow={false}
                    >
                      {notifications.length === 0 && (
                        <ListItem>
                          <ListItemText>No notifications found</ListItemText>
                        </ListItem>
                      )}
                      {notifications.map((notification, index) => {
                        return (
                          <ListItem disablePadding key={notification.date + index}>
                            <ListItemButton
                              href={notification.redirectUrl}
                              onClick={() => {
                                markAsViewed(notification)
                                  .then(() => {
                                    if (notification.notificationCode) {
                                      clickToNavigate(NotificationLink[notification.notificationCode]);
                                    }
                                  })
                                  .catch(() => {});
                              }}
                            >
                              <ListItemIcon>
                                {notification.image && (
                                  <Box width={40}>
                                    <img src={notification.image} alt='Profile' />
                                  </Box>
                                )}
                                {!notification.image && (
                                  <Box width={40}>
                                    <img src={notificationImage} alt='Profile' />
                                  </Box>
                                )}
                              </ListItemIcon>
                              <ListItemText>
                                <Typography variant='body1' paragraph>
                                  <b>{notification.message}</b>
                                </Typography>
                                <Stack direction='row' spacing={3}>
                                  <Box>
                                    <Typography>Date</Typography>
                                    <Typography>{formatDate(notification.date)}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography>Time</Typography>
                                    <Typography>{formatTime(notification.date)}</Typography>
                                  </Box>
                                </Stack>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </InfiniteScroll>
                  </Paper>
                </PopoverContent>
              </Popover>
            </Stack>
            <Tooltip title='Open menu'>
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar alt={authenticatedUser.fullName} src={authenticatedUser.imageUrl ?? ''}>
                  {authenticatedUser.fullName[0]}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {profileMenu.map((menuItem) => (
                <MenuItem component={Link} key={menuItem.label} to={menuItem.to} onClick={handleCloseUserMenu}>
                  <Typography textAlign='center'>{menuItem.label}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={handleLogout}>
                <Typography textAlign='center'>Log out</Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </TulpAppBar>
      {/* <Paper sx={{ backgroundColor: teal[50], position: 'fixed', top: 64, width: '100%' }}>
        <Container maxWidth='lg'>
          <Toolbar variant='dense'>
            <VerifiedRoundedIcon sx={{ mr: 2, color: teal[600] }} />
            <Typography>
              <span style={{ fontWeight: 900 }}>SALES</span>
              <span style={{ fontWeight: 100 }}>HOOKUP is made possible with the help of our amazing sponsor</span>,{' '}
              <span style={{ fontWeight: 900, color: 'rgb(1, 119, 55)' }}>pipedrive</span>.{' '}
              <span style={{ fontWeight: 100 }}>
                Go to <Link to='/'>Member Perks</Link> for you exclusive offer.
              </span>
            </Typography>
          </Toolbar>
        </Container>
      </Paper> */}
    </>
  );
}
