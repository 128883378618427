import { DateTime, getDateTime } from './date';

type Timeout = number | null;
type Data = unknown | null;

function store(storage: Storage, key: string, data: Data, timeout: Timeout = null) {
  const expiration = timeout && DateTime.now().plus({ seconds: timeout }).toISO();
  storage.setItem(key, JSON.stringify({ data, expiration }));
}

function retrieve(storage: Storage, key: string, data: Data = null) {
  try {
    if (!storage.getItem(key)) {
      return data;
    }
    const item = JSON.parse(storage.getItem(key) || ''),
      expired = !!item.expiration && getDateTime(item.expiration) < DateTime.now();
    if (expired) {
      storage.removeItem(key);
      return data;
    }
    return item.data ?? data;
  } catch (err) {
    return data;
  }
}

export function localStore<T = Data>(key: string, data: T, timeout: Timeout = null) {
  try {
    store(localStorage, key, data, timeout);
  } catch (err) {
    // localStorage is not available in some browsers (e.g. Safari in private mode or blocking cookies)
  }
}

export function localRetrieve(key: string, data: Data = null) {
  try {
    return retrieve(localStorage, key, data);
  } catch (err) {
    // localStorage is not available in some browsers (e.g. Safari in private mode or blocking cookies)
    return data;
  }
}

export function localStorageRemove(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    // localStorage is not available in some browsers (e.g. Safari in private mode or blocking cookies)
  }
}

export function sessionStore(key: string, data: Data, timeout: Timeout = null) {
  store(sessionStorage, key, data, timeout);
}

export function sessionPop(key: string, data: Data = null) {
  const value = retrieve(sessionStorage, key, data);
  sessionStorage.removeItem(key);
  return value;
}
