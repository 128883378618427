let storage = {};
const REMEMBER_ME_KEY = 'remember_me';

export function getItem(key) {
  if (localStorage && getRememberMe()) {
    return localStorage.getItem(key);
  } else if (sessionStorage) {
    return sessionStorage.getItem(key);
  } else {
    const result = storage[key];
    return result === undefined ? null : result; // imitation of the storage behaviour
  }
}

export function setItem(key, value) {
  if (localStorage && getRememberMe()) {
    localStorage.setItem(key, value);
  } else if (sessionStorage) {
    sessionStorage.setItem(key, value);
  } else {
    storage = {
      ...storage,
      [key]: value
    };
  }
}

export function getRememberedItem(key) {
  if (localStorage) {
    return localStorage.getItem(key);
  } else if (sessionStorage) {
    return sessionStorage.getItem(key);
  } else {
    const result = storage[key];
    return result === undefined ? null : result; // imitation of the storage behaviour
  }
}

export function setRememberedItem(key, value) {
  if (localStorage) {
    localStorage.setItem(key, value);
  } else if (sessionStorage) {
    sessionStorage.setItem(key, value);
  } else {
    storage = {
      ...storage,
      [key]: value
    };
  }
}

export function removeItem(key) {
  if (localStorage) {
    localStorage.removeItem(key);
  }
  if (sessionStorage) {
    sessionStorage.removeItem(key);
  }
}

export function getRememberMe() {
  return !!localStorage && localStorage.getItem(REMEMBER_ME_KEY) === 'y';
}

export function setRememberMe(flag) {
  localStorage && localStorage.setItem(REMEMBER_ME_KEY, flag ? 'y' : 'n');
}
