import { QueryFunctionContext } from '@tanstack/react-query';

import { request } from '@request';

import { SelectTimecycleReqBodyType, TimeCycleDataType } from '../types';
import { URL_API_BASE } from './const';

export async function getAuthUserAllTimeCycle(args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/time-cycle/all`, { signal: args?.signal });
  return res.data as TimeCycleDataType[];
}

export async function selectTimecycle(data: SelectTimecycleReqBodyType) {
  const res = await request.post(`${URL_API_BASE}/time-cycle/select`, { data });
  return res.data as TimeCycleDataType;
}
