import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { AppContainer } from '@pages/app-container';
import { RootBoundary } from '@components/RootBoundary';

import { SentryConfiguration } from '../sentry-conf';
import { GuestUserRoutes } from './guest-user-routes';
import { RegisteredUserRoutes } from './registered-user-routes';
import { ValidationUserRoutes } from './validation-user-routes';

Sentry.init(SentryConfiguration);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const AppRoutes = sentryCreateBrowserRouter([
  {
    element: <AppContainer />,
    errorElement: <RootBoundary />,
    children: [
      GuestUserRoutes,
      RegisteredUserRoutes,
      ...ValidationUserRoutes,
      {
        path: 'guest/group-hookups/details/:uuid',
        lazy: () => import('@pages/guest-group-hookup-details')
      },
      {
        path: 'post/:postId',
        lazy: () => import('@pages/post-details')
      },
      {
        path: 'terms-and-conditions',
        lazy: () => import('@pages/terms-and-conditions')
      }
    ]
  }
]);
