import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { closeSnackbar, SnackbarProvider } from 'notistack';

import { QueryClientProvider } from './query/conf';
import { ThemeProvider } from './theme';
import { IconButton } from './tulp/components';

export function AppProviders({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          action={(snackbarId) => (
            <IconButton size='small' sx={{ color: 'white' }} onClick={() => closeSnackbar(snackbarId)}>
              <CloseRoundedIcon fontSize='small' />
            </IconButton>
          )}
        >
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
