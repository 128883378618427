import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ForgotPasswordValidationSchemaType, ResetPasswordValidationSchemaType } from '@form';
import { request, RequestOptions } from '@request';
import { BASE_URL } from '@utils/consts';
import { getRememberedItem, removeItem, setItem } from '@utils/storage';

import { URL_API_BASE } from '.';

export function getAuthToken(params: RequestOptions) {
  return request.post(`${URL_API_BASE}/auth/refreshToken`, params);
}

export function getForumToken(username: string, params?: QueryFunctionContext) {
  return request.get(`${URL_API_BASE}/forum/forum-token?username=${username}`, params);
}

export const refreshAuthLogic = (failedRequest: AxiosError) => {
  const config = typeof failedRequest?.config?.data === 'string' ? JSON.parse(failedRequest.config.data) : {};
  const refreshToken = getRememberedItem('RTN');

  if (refreshToken && !config.skipRetry) {
    return getAuthToken({
      data: {
        refreshToken
      }
    })
      .then((tokenRefreshResponse) => {
        setItem('session', JSON.stringify({ token: tokenRefreshResponse.data.accessToken }));
        if (failedRequest.response?.config.headers) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          failedRequest.response.config.headers = { ...failedRequest.response.config.headers } as any;
          failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.accessToken;
          failedRequest.response.config.headers['Content-Type'] = 'application/json';
        }

        return Promise.resolve();
      })
      .catch(() => {
        removeItem('RTN');
        window.location.href = `/login?next=${window.location.pathname}`;
      });
  } else {
    return Promise.reject(failedRequest.response);
  }
};

export async function forgotPassword(data: ForgotPasswordValidationSchemaType) {
  const res = await request.post(`${BASE_URL}/user/password/reset`, { data });
  return res.data;
}

export async function resetPassword(data: ResetPasswordValidationSchemaType) {
  const res = await request.put(`${BASE_URL}/password/reset/confirm`, { data });
  return res.data;
}
