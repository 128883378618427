import { isEmpty } from 'remeda';

import { UnknownObject } from '@tulp';

export function attachQueryParams(url: string, params?: UnknownObject) {
  if (params && !isEmpty(params)) {
    const searchParams = new URLSearchParams();

    for (const key in params) {
      if (params[key] !== undefined) {
        searchParams.append(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    }
    url += '?' + searchParams.toString();
  }
  return url;
}
