import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';

import { List } from '../List';
import { ListItemButton, ListItemButtonProps } from '../ListItemButton';
import { ListItemIcon } from '../ListItemIcon';
import { ListItemText } from '../ListItemText';

export type NestedListItemPropsType = {
  mainText: string;
  mainIcon: React.ReactNode;
  children: React.ReactNode;
  mainItemSx?: ListItemButtonProps['sx'];
};

export function NestedListItem(props: Readonly<NestedListItemPropsType>) {
  const [open, setOpen] = useState(true);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <>
      <ListItemButton sx={props.mainItemSx} onClick={handleClick}>
        <ListItemIcon>{props.mainIcon}</ListItemIcon>
        <ListItemText primary={props.mainText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {props.children}
        </List>
      </Collapse>
    </>
  );
}
