import { useState } from 'react';
import * as Sentry from '@sentry/react';

import { FeedbackFormValidationSchema, FeedbackFormValidationSchemaType, TextFormField, useForm } from '@form';
import { useGetAuthenticatedUser } from '@query';
import { Alert, Button, Stack, useToggleState } from '@tulp';

export type FeedbackFormPropType = {
  includeReplay?: boolean;
  redirectToHomePageLogic?: boolean;
};

const redirectToHomePageLogicCountdown = 5;

export function FeedbackForm(props: Readonly<FeedbackFormPropType>) {
  const [successSent, setSuccessSent] = useState(false);
  const [countDown, setCountDown] = useState(redirectToHomePageLogicCountdown);

  const { data: authenticatedUser } = useGetAuthenticatedUser();

  const [isSending, startSend, stopSend] = useToggleState();
  const { control, handleSubmit } = useForm<FeedbackFormValidationSchemaType>({
    zodSchema: FeedbackFormValidationSchema,
    defaultValues: { name: authenticatedUser?.fullName ?? '', email: authenticatedUser?.username ?? '', message: '' }
  });

  async function onSubmit(values: FeedbackFormValidationSchemaType) {
    startSend();
    await Sentry.sendFeedback(values, { includeReplay: props.includeReplay });
    stopSend();
    setSuccessSent(true);

    if (props.redirectToHomePageLogic) {
      const countDownInterval = window.setInterval(() => {
        setCountDown((c) => {
          if (c === 0) {
            window.clearInterval(countDownInterval);
            return c;
          }
          return c - 1;
        });
      }, 1000);

      window.setTimeout(() => {
        window.location.href = '/home';
      }, redirectToHomePageLogicCountdown * 1000);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <TextFormField required disabled={!!authenticatedUser || isSending || successSent} control={control} name='name' label='Name' />
        <TextFormField required disabled={!!authenticatedUser || isSending || successSent} control={control} name='email' label='Email' />
        <TextFormField required disabled={isSending || successSent} control={control} name='message' label='Message' multiline minRows={4} />
        {successSent ? (
          <Alert severity='success'>
            Thanks for sharing your feedback with us. We appreciate your help!{' '}
            {props.redirectToHomePageLogic ? <code>You will be redirected to the home page in {countDown} seconds!</code> : null}
          </Alert>
        ) : null}
        <Button sx={{ alignSelf: 'center' }} variant='contained' loading={isSending} disabled={successSent} type='submit'>
          Send feedback
        </Button>
      </Stack>
    </form>
  );
}
