import { QueryFunctionContext } from '@tanstack/react-query';

import { TagFormValidationSchemaType } from '@form';
import { request } from '@request';

import { TagType } from '../types';
import { URL_API_BASE } from './const';

export async function getTags({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/tags`, { signal });
  return res.data as TagType[];
}

export async function createTag(data: TagFormValidationSchemaType['tags'][number]) {
  const res = await request.post(`${URL_API_BASE}/tags`, { data });
  return res.data as TagType;
}

export async function updateTag({ slug, data }: { slug: string; data: TagFormValidationSchemaType['tags'][number] }) {
  const res = await request.put(`${URL_API_BASE}/tags/${slug}`, { data });
  return res.data as TagType;
}

export async function deleteTag(slug: string) {
  const res = await request.delete(`${URL_API_BASE}/tags/${slug}`);
  return res.data as TagType;
}
