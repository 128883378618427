import { Provider } from 'react-redux';
import { Navigate, RouteObject } from 'react-router-dom';

import { ChatStore } from '@chat-app/store';

import { RegisteredUserAppContainer } from '@pages/app-container';

import { queryClient } from '../query/query-client';
import { getAuthUserLoader } from './root-loader';

export const RegisteredUserRoutes: RouteObject = {
  loader: getAuthUserLoader(queryClient),
  element: (
    <Provider store={ChatStore}>
      <RegisteredUserAppContainer />
    </Provider>
  ),
  children: [
    {
      path: '/',
      element: <Navigate to='/home' replace />
    },
    {
      path: 'home',
      lazy: () => import('@pages/home')
    },
    {
      path: 'calendar',
      lazy: () => import('@pages/calendar')
    },
    {
      path: 'timecycles',
      lazy: () => import('@pages/timecycles')
    },
    {
      path: 'users',
      lazy: () => import('@pages/user/user-list-page')
    },
    {
      path: 'users/create',
      lazy: () => import('@pages/user/create-user-page')
    },
    {
      path: 'users/:uuid?',
      lazy: () => import('@pages/user/user-details-page')
    },
    {
      path: 'users/:uuid/edit',
      lazy: () => import('@pages/user/edit-user-page')
    },
    {
      path: 'reviews',
      lazy: () => import('@pages/reviews')
    },
    {
      path: 'profile',
      lazy: () => import('@pages/profile')
    },
    {
      path: 'invite',
      lazy: () => import('@pages/invite')
    },
    {
      path: 'referrals',
      lazy: () => import('@pages/referrals')
    },
    {
      path: 'chat/:conversationId?',
      lazy: () => import('@pages/chat')
    },
    {
      path: 'settings',
      lazy: () => import('@pages/settings')
    },
    {
      path: 'my-network',
      lazy: () => import('@pages/my-network')
    },
    {
      path: 'group-hookups',
      lazy: () => import('@pages/group-hookup-list')
    },
    {
      path: 'group-hookups/details/:uuid',
      lazy: () => import('@pages/group-hookup-details')
    },
    {
      path: 'group-hookups/details/:uuid/conferencing-room',
      lazy: () => import('@pages/conferencing-room')
    },
    {
      path: 'group-hookups/details/:uuid/interactive-live-streaming-room',
      lazy: () => import('@pages/interactive-live-streaming-room')
    },
    {
      path: 'hookups/:hookupId/edit',
      lazy: () => import('@pages/one-to-one-hookup-edit')
    },
    {
      path: 'reschedule/:hookupId',
      lazy: () => import('@pages/reschedule')
    },
    {
      path: 'timeslots',
      lazy: () => import('@pages/one-to-one-hookup')
    },
    {
      path: 'video/:videoCallId',
      lazy: () => import('@pages/videocalls')
    },
    {
      path: 'member-perks',
      lazy: () => import('@pages/member-perks')
    },
    {
      path: 'champions',
      lazy: () => import('@pages/champions')
    }
  ]
};
