import { QueryFunctionContext } from '@tanstack/react-query';

import { request } from '@request';

import { URL_API_BASE } from '.';

export async function getJobPosition({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/job-positions`, { signal });
  return res.data as string[];
}
