import { FieldValues, useForm as _useForm, UseFormProps } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ZodType } from 'zod';

export type UseFormPropsType<TFieldValues extends FieldValues = FieldValues, TContext = unknown> = UseFormProps<TFieldValues, TContext> & {
  zodSchema?: ZodType;
};

export function useForm<TFieldValues extends FieldValues = FieldValues, TContext = unknown>(props?: UseFormPropsType<TFieldValues, TContext>) {
  return _useForm<TFieldValues, TContext>({ ...(props ?? {}), resolver: props?.zodSchema ? zodResolver(props.zodSchema) : undefined });
}
