import { Link, LinkProps } from 'react-router-dom';

import { useIsButtonLinkActive } from '@tulp';

import { Button, ButtonProps } from './button';

export function ButtonLink(props: ButtonProps & LinkProps) {
  const checkActive = useIsButtonLinkActive();

  return <Button variant='text' selected={checkActive(props.to.toString())} component={Link} {...props} />;
}
