import { QueryFunctionContext } from '@tanstack/react-query';

import { ForumApiV3ListResponseDataType, ForumApiV3SimpleResponseDataType, ForumPostReplyDataType, ForumVoteOnPostPayloadType } from '@query';
import { UnknownObject } from '@tulp';

import { forumApiV3Request } from '.';

export async function forumVoteOnPost({ pid, delta }: ForumVoteOnPostPayloadType) {
  const res = await forumApiV3Request.put(`/posts/${pid}/vote`, { delta });
  return res.data as ForumApiV3SimpleResponseDataType<UnknownObject>;
}

export async function forumGetPostReplies(pid: number, { signal }: QueryFunctionContext) {
  const res = await forumApiV3Request.get(`/posts/${pid}/replies`, { signal });
  return res.data as ForumApiV3ListResponseDataType<ForumPostReplyDataType, 'replies'>;
}

export async function forumGetPostRawContent(pid: number, { signal }: QueryFunctionContext) {
  const res = await forumApiV3Request.get(`/posts/${pid}/raw`, { signal });
  return res.data as ForumApiV3SimpleResponseDataType<{ content: string }>;
}
