import { useReducer } from 'react';

import { UnknownObject } from '../type';

type Updater<T> = Partial<T> | ((data: T) => UnknownObject);

export function useData<T extends UnknownObject>(initial: T) {
  return useReducer((oldData: T, updater: Updater<T>) => {
    if (typeof updater == 'function') {
      return { ...oldData, ...updater(oldData) };
    } else {
      return { ...oldData, ...updater };
    }
  }, initial);
}
