import { styled } from '@mui/material';

import { Tab, Tabs } from '..';

export type TabSelectPropsType = {
  ariaLabel?: string;
  value: number;
  values: { value: number; label: string }[];
  onChangeValue?: (value: number) => void;
};

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />)({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'white'
  },
  '& .MuiTabs-flexContainer': {}
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(1),
  '&.MuiTab-root': {
    borderRadius: '4px',
    padding: '12px 30px',
    color: 'white'
  },
  '&.Mui-selected': {
    color: 'black',
    backgroundColor: 'white'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'white'
  },
  ':hover': {
    backgroundColor: '#ffffff63'
  }
}));

export function TabSelect(props: Readonly<TabSelectPropsType>) {
  function handleChange(_event: React.SyntheticEvent, newValue: number) {
    props.onChangeValue?.(props.values[newValue].value);
  }

  return (
    <StyledTabs
      sx={(theme) => ({ py: 2, px: 1.5, borderRadius: 1, backgroundColor: theme.palette.gray.dark })}
      value={props.value}
      onChange={handleChange}
      aria-label={props.ariaLabel}
    >
      {props.values.map((v) => (
        <StyledTab key={v.value} label={v.label} />
      ))}
    </StyledTabs>
  );
}
