import { useEffect } from 'react';

import { useGetAuthenticatedUser } from '@query';
import { trackNotFoundPage } from '@utils/metrics';
import { Box, Button, Stack, Typography } from '@tulp';

import error404 from './error_404.svg';

export function NotFoundPage() {
  const { data: authenticatedUser, status } = useGetAuthenticatedUser();

  useEffect(() => {
    trackNotFoundPage(authenticatedUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Stack alignItems='center' spacing={8}>
      <Box>
        <img alt='404 error' src={error404} />
      </Box>
      <Typography variant='title' textAlign='center'>
        Page not found
      </Typography>
      <Typography variant='subtitle1' gutterBottom paragraph>
        The page you are looking for does not seem to exist
      </Typography>
      <Button variant='contained' size='large' href='/home'>
        GO HOME
      </Button>
    </Stack>
  );
}
