import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { loginCallback } from '@utils/auth';

import { verifyUser } from '../../services/userService';
import { setRememberedItem } from '../../utils/storage';

export default function Verification() {
  const params = new URLSearchParams(useLocation().search);
  const [error, setError] = useState();

  useEffect(() => {
    verifyUser({ token: params.get('token') })
      .then((response) => {
        setRememberedItem('askCalendarSync', true);
        setRememberedItem('askForImage', true);
        sessionStorage.setItem('show_tutorial', 'true');

        loginCallback(response.data, '/profile');
      })
      .catch((err) => {
        if (err.response?.data) {
          setError(err.response.data.message);
        } else {
          setError('An error occurred. Please try again later.');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{error ? error : null}</>;
}
