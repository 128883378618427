import { createQueryKeys } from '@lukemorales/query-key-factory';

export const ReferralQueryStore = createQueryKeys('referral', {
  admin: {
    queryKey: null,
    queryFn: () => null
  },
  user: {
    queryKey: null,
    queryFn: () => null
  }
});
