import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getLastReviews, getReviewRating, getReviews } from '../query-fn';

export const ReviewQueryStore = createQueryKeys('review', {
  reviews: {
    queryKey: null,
    queryFn: (ctx) => getReviews(ctx)
  },
  'last-reviews': {
    queryKey: null,
    queryFn: (ctx) => getLastReviews(ctx)
  },
  rating: {
    queryKey: null,
    queryFn: (ctx) => getReviewRating(ctx)
  }
});
