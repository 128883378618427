import { QueryFunctionContext } from '@tanstack/react-query';

import { FutureHookupsForUserMockData } from '@mocks/data';

import { request } from '@request';
import { formatDateAndTime } from '@tulp';

import { AdminHookupsQueryParamsType } from '../queries';
import { HookupDataType, NextHookupDataType, TimeCycleDataType } from '../types';
import { exportItems, URL_API_BASE } from '.';

export async function getHookupAsAdmin(id: string, args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/hookup-admin/hookup/${id}`, { signal: args?.signal });
  return res.data as HookupDataType;
}

export async function getNextHookupDetails({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/hookup-user/next-hookup-details`, { signal });
  return res.data as NextHookupDataType;
}

export async function getHookupsForUser({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/hookup-user/hookups`, { signal });
  return res.data as HookupDataType[];
}

export async function getHookupsForAdminUser({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/hookup-admin/all-hookups`, { signal });
  return res.data as HookupDataType[];
}

export async function getTimeCyclesForAdminUser(params?: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/hookup-admin/time-cycle`, { signal: params?.signal });
  return res.data as TimeCycleDataType[];
}

export function exportHookupsToCsv(params: AdminHookupsQueryParamsType) {
  const csvFileName = `Hookups-Report-${formatDateAndTime(new Date())}.csv`;
  return exportItems(`${URL_API_BASE}/hookup-admin/hookup/export`, params, csvFileName);
}

export async function getFutureHookupsForAuthUser() {
  const res = await Promise.resolve({ data: FutureHookupsForUserMockData });
  //const res = await request.get(`${URL_API_BASE}/hookup-user/future-hookups`, { signal });
  return res.data as HookupDataType[];
}

export async function updateHookupAsAdmin(data: HookupDataType, args?: QueryFunctionContext | undefined) {
  const res = await request.put(`${URL_API_BASE}/hookup-admin/hookup`, { data, signal: args?.signal });
  return res.data as HookupDataType;
}
