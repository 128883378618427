import { createTheme, ThemeOptions } from '@mui/material';

declare module '@mui/material' {
  interface ButtonPropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
  }

  interface Palette {
    inherit: Palette['primary'];
    gray: Palette['primary'];
    black: Palette['primary'];
    default: Palette['primary'];
  }

  interface PaletteOptions {
    inherit?: PaletteOptions['primary'];
    gray?: PaletteOptions['primary'];
    black?: PaletteOptions['primary'];
    default?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    title: string;
    subtitle: string;
    body: string;
    'body-bold': string;
  }

  interface TypographyVariantsOptions {
    title?: string;
    subtitle?: string;
    body?: string;
    'body-bold'?: string;
  }
}

declare module '@mui/system' {
  interface Shape {
    getBorder: (color: string) => string;
    formControlSize: number;
  }
}

export const defaultThemeOptions: ThemeOptions = {
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Mangueira', 'Montserrat'].join(','),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    title: {
      fontFamily: 'Mangueira',
      fontSize: '1.5rem',
      fontWeight: 600
    },
    subtitle1: {
      fontFamily: 'Mangueira',
      fontSize: '1.1rem',
      fontWeight: 600
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 500
    },
    subtitle3: {
      fontSize: '1rem',
      fontWeight: 600
    },
    subtitle4: {
      fontFamily: 'Mangueira',
      fontSize: '14px',
      fontWeight: 600
    },
    subtitle5: {
      fontSize: '12px',
      fontWeight: 600
    },
    subtitle6: {
      fontSize: '12px',
      fontWeight: 400
    },
    body1: {
      fontFamily: 'Montserrat',
      fontWeight: 500
    },
    body4: {
      fontFamily: 'Montserrat',
      fontSize: '10px',
      fontWeight: 500
    },
    button: {
      textTransform: 'inherit'
    },
    caption: {
      color: 'gray',
      fontSize: '0.8rem'
    }
  },
  spacing: 4,
  '&:active': {
    backgroundColor: 'rgb(95 115 182 / 10%)'
  },
  palette: {
    primary: {
      main: '#3851a4',
      contrastText: '#fff'
    },
    secondary: {
      light: '#27274C',
      main: '#E6EDFD',
      dark: '#ECEFF5'
    },
    black: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#000'
    },
    gray: {
      light: '#FBFBFB',
      main: '#5A6774',
      dark: '#5A6774',
      contrastText: '#fff'
    },
    default: {
      light: '#212121',
      main: '#212121',
      dark: '#212121',
      contrastText: 'white'
    }
  }
};

// Default theme: https://mui.com/customization/default-theme/#main-content
export const defaultTheme = createTheme(defaultThemeOptions);
