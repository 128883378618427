import * as z from 'zod';

import { UserDataType } from '@query';

import { LocationValidationSchema, PasswordValidationSchema, PhoneNumberValidationSchema } from './common';
import { getInvalidFieldMessage } from './common-messages';
import { RegisterFormValidationSchemaObject } from './register';

export const UserRequiredFieldsValidationSchema = RegisterFormValidationSchemaObject.omit({ password: true, confirmPassword: true });

export function userHasIncompleteProfile(user: UserDataType) {
  const data = UserRequiredFieldsValidationSchema.safeParse(user);
  if (data.success === false) {
    return Object.keys(data.error.formErrors.fieldErrors);
  }
  return false;
}

export const UserFormValidationSchema = RegisterFormValidationSchemaObject.omit({
  password: true,
  confirmPassword: true,
  acceptTermsAndConditions: true,
  location: true
}).extend({
  fullName: z.string().optional(),
  phoneNumber: PhoneNumberValidationSchema.nullable().optional(),
  countryCode: z.string().nullable().optional(),
  jobPosition: z.string(),
  companyName: z.string().optional().nullable(),
  industry: z.string().nullable(),
  companySize: z.string().nullable(),
  yearsOfExperience: z.string().nullable(),
  seniorityLevel: z.string().nullable(),
  personalInformation: z.string().nullable(),
  location: LocationValidationSchema.nullable()
});
export type UserFormValidationSchemaType = z.infer<typeof UserFormValidationSchema>;

export const UpdateUserEmailFormValidationSchemaObject = z.object({
  email: z.string().email({ message: getInvalidFieldMessage('email') })
});
export type UpdateUserEmailFormValidationSchemaType = z.infer<typeof UpdateUserEmailFormValidationSchemaObject>;

export const UpdateUserPasswordFormValidationSchemaObject = RegisterFormValidationSchemaObject.pick({ password: true, confirmPassword: true }).extend({
  oldPassword: PasswordValidationSchema
});

export type UpdateUserPasswordFormValidationSchemaType = z.infer<typeof UpdateUserPasswordFormValidationSchemaObject>;
