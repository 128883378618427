import { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Stack } from '@tulp';

export type TutorialDialogProps = {
  open: boolean;
  onClose?: () => void;
};

export function TutorialDialog(props: TutorialDialogProps) {
  const [loadingTutorial, setLoadingTutorial] = useState(true);

  return (
    <Dialog fullScreen open={props.open} onClose={props.onClose}>
      <DialogTitle onClickCloseIcon={props.onClose}>Tutorial</DialogTitle>
      <DialogContent>
        {loadingTutorial && <Skeleton variant='rounded' width='100%' height='100%' />}
        <iframe
          allowFullScreen
          style={{ border: 0, height: '100%', width: '100%' }}
          src='https://saleshookup.journey.io/p/80fd8baed55e4d708f44e9cd9e65d77b'
          onLoad={() => setLoadingTutorial(false)}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction='row' spacing={2}>
          <Button variant='text' onClick={props.onClose}>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
