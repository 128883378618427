const journeyIframeLinksMap = {
  home: 'homepage/how-to-use-the-saleshookup-homepage-4b4488cb',
  timeslots: 'timeslots/how-to-choose-time-slots-for-your-1:1-hookups-d875d1e4',
  settings: 'settings/how-to-edit-your-profile-and-settings-ad472ac8',
  'group-hookups': 'settings/how-to-join-and-host-group-hookups-b691953c',
  'my-network': 'mynetwork/how-to-interact-with-your-network-c3b7da39',
  invite: 'mynetwork/how-to-interact-with-your-network-c3b7da39',
  chat: 'mynetwork/how-to-interact-with-your-network-c3b7da39',
  profile: 'mynetwork/how-to-edit-your-profile-and-settings-d1903157'
};

export function getJourneyIframeLinkByLocation() {
  const key = window.location.pathname.split('/')[1];

  return journeyIframeLinksMap[key as keyof typeof journeyIframeLinksMap] ?? 'homepage/before-your-first-meeting--read-saleshookup-guidelines-cd8a3a8f';
}
