import { QueryFunctionContext } from '@tanstack/query-core';

import { request } from '@request';

import { URL_API_BASE } from '.';

export async function getChatToken(params: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/chat/access-token`, params);
  return res.data;
}

export async function createTwilioUser(userUUID: string) {
  const res = await request.post(`${URL_API_BASE}/chat/user/${userUUID}`);
  return res.data;
}

export async function generateTwilioIds() {
  const res = await request.post(`${URL_API_BASE}/admin/user/generate-twilio-ids`);
  return res.data;
}

export async function deleteTwilioIds() {
  const res = await request.delete(`${URL_API_BASE}/admin/user/delete-twilio-ids`);
  return res.data;
}
