import { QueryFunctionContext } from '@tanstack/react-query';

import { request } from '@request';
import { formatDateAndTime } from '@tulp';

import { ReviewsQueryParamsType } from '../queries/review';
import { LastReviewDataType, ReviewDataType } from '../types';
import { exportItems, URL_API_BASE } from '.';

export async function getReviews({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/reviews`, { signal });
  return res.data as ReviewDataType[];
}

export async function getLastReviews({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/reviews/last-reviews`, { signal });
  return res.data as LastReviewDataType[];
}

export async function getReviewRating({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/reviews/rating`, { signal });
  return res.data as number;
}

export function exportReviewsToCsv(params: ReviewsQueryParamsType) {
  const csvFileName = `Reviews-Report-${formatDateAndTime(new Date())}.csv`;
  return exportItems(`${URL_API_BASE}/reviews/export`, params, csvFileName);
}
