import MUIMenu, { MenuProps as MUIMenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export interface MenuProps extends MUIMenuProps {}

const StyledMenu = styled(MUIMenu)<MenuProps>(() => ({
  marginTop: '0.6rem',
  '& .MuiPaper-root': {
    borderRadius: '3px',
    boxShadow: '0px 0px 1px rgb(32 33 34 / 28%), 0px 4px 8px -2px rgb(32 33 34 / 20%);'
  },
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0
  },
  '& .MuiMenuItem-root': {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  }
}));

export function Menu(props: MenuProps) {
  return (
    <StyledMenu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      keepMounted
      {...props}
    >
      {props.children}
    </StyledMenu>
  );
}
