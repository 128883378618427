import { Button, CommonDialog, useToggleState } from '@tulp';

import { FeedbackForm } from './feedback-form';

export function Feedback() {
  const [isOpen, open, close] = useToggleState();

  function handleClose() {
    close();
  }

  return (
    <>
      {isOpen && (
        <CommonDialog
          open
          fullWidth
          noActions
          info='Please share any issues you are experiencing. We will send this information, along with your interaction on the platform, to the team to fix the issue.'
          title='Feedback'
          onClose={handleClose}
        >
          <FeedbackForm includeReplay />
        </CommonDialog>
      )}
      <Button
        id='feedback-button'
        color='info'
        variant='contained'
        sx={(theme) => ({
          position: 'fixed',
          top: '40%',
          right: 0,
          transform: 'rotate(-90deg) translate(0, -90%)',
          transformOrigin: '100% 0',
          zIndex: theme.zIndex.appBar
        })}
        onClick={open}
      >
        Feedback
      </Button>
    </>
  );
}
