import { QueryFunctionContext } from '@tanstack/react-query';

import { request } from '@request';
import { UnknownObject } from '@tulp';

import { GroupHookupSettingsDetailsType, UpdateGroupHookupQueryParamsType } from '../types/group-hookup-settings';
import { attachQueryParams } from '../utils';
import { URL_API_BASE } from './const';

export async function getGroupHookupSettingsByOrganization(organizationUUID: string, args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/group-hookup-settings/${organizationUUID}`, { signal: args?.signal });
  return res.data as GroupHookupSettingsDetailsType;
}

export async function updateGroupHookupSettingsByOrganization({ organizationUUID, params }: UpdateGroupHookupQueryParamsType) {
  const res = await request.put(attachQueryParams(`${URL_API_BASE}/group-hookup-settings/${organizationUUID}`, params));
  return res.data as UnknownObject;
}
