import { NavigateFunction, redirect } from 'react-router-dom';

export const redirectToHome = () => redirect('/home');

export const redirectToProfile = () => redirect('/profile');

export const redirectToLogin = () => {
  if (window.location.pathname === '/') {
    return redirect('/login');
  }
  return redirect(`/login/?next=${location.pathname}`);
};

export const navigateToLogin = (navigate: NavigateFunction) => {
  if (window.location.pathname === '/') {
    return navigate('/login');
  }
  return navigate(`/login/?next=${location.pathname}`);
};

export const redirectToNotFound = () => {
  return redirect(`/not-found`);
};

export const redirectToRegister = () => redirect('/register');
