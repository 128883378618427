import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Client, Conversation, Message, Participant } from '@twilio/conversations';
import { bindActionCreators } from 'redux';

import { useGetChatToken } from '@query';

import { ConversationAttributes, ParticipantAttributes } from '../api';
import { handlePromiseRejection } from '../helpers';
import { actionCreators, AppState } from '../store';
import { AddMessagesType, SetParticipantsType, SetUnreadMessagesType } from '../types';

async function loadUnreadMessagesCount(convo: Conversation, updateUnreadMessages: SetUnreadMessagesType) {
  let count = 0;

  try {
    count = (await convo.getUnreadMessagesCount()) ?? (await convo.getMessagesCount());
  } catch (e) {
    console.error('getUnreadMessagesCount threw an error', e);
  }

  updateUnreadMessages(convo.sid, count);
}

async function handleParticipantsUpdate(participant: Participant, updateParticipants: SetParticipantsType) {
  const result = await participant.conversation.getParticipants();
  updateParticipants(result, participant.conversation.sid);
}

export function useInitChat() {
  const dispatch = useDispatch();
  const {
    updateClientChat,
    updateConnectionState,
    upsertMessages,
    updateLoadingState,
    updateParticipants,
    updateUser,
    updateUnreadMessages,
    startTyping,
    endTyping,
    upsertConversation,
    removeMessages,
    removeConversation,
    updateCurrentConversation,
    addNotifications,
    clearAttachments,
    updateTimeFormat
  } = bindActionCreators(actionCreators, dispatch);

  const sid = useSelector((state: AppState) => state.sid);

  const [clientIteration, setClientIteration] = useState(0);
  const sidRef = useRef('');
  sidRef.current = sid;

  const { data: token, refetch } = useGetChatToken();

  const updateTypingIndicator = (participant: Participant, sid: string, callback: (sid: string, user: string) => void) => {
    const {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      attributes: { friendlyName },
      identity
    } = participant;
    if (identity === localStorage.getItem('username')) {
      return;
    }
    callback(sid, identity || friendlyName || '');
  };

  async function upsertMessage(message: Message, upsertMessages: AddMessagesType, updateUnreadMessages: SetUnreadMessagesType) {
    //transform the message and add it to redux
    await handlePromiseRejection(async () => {
      if (sidRef.current === message.conversation.sid) {
        await message.conversation.advanceLastReadMessageIndex(message.index);
      }
      upsertMessages(message.conversation.sid, [message]);
      await loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
    }, addNotifications);
  }

  useEffect(() => {
    if (!token) return;

    const client = new Client(token as string, { logLevel: 'error' });
    updateClientChat(client);

    // Removed firebase integration doesn't work properly
    // const fcmInit = async () => {
    //   await subscribeFcmNotifications(client);
    // };

    // fcmInit().catch(() => {
    //   console.error('FCM initialization failed: no push notifications will be available');
    // });

    client.on('conversationJoined', (conversation) => {
      upsertConversation(conversation);
      const conversationAttributes = conversation.attributes as ConversationAttributes;

      conversation.on('typingStarted', (participant) => {
        const attributes = participant.identity ? conversationAttributes[participant.identity].current : { name: '' };
        handlePromiseRejection(() => updateTypingIndicator(participant, conversation.sid, (suid) => startTyping(suid, attributes.name)), addNotifications);
      });

      conversation.on('typingEnded', async (participant) => {
        const attributes = participant.attributes as ParticipantAttributes;
        await handlePromiseRejection(
          async () => updateTypingIndicator(participant, conversation.sid, (suid) => endTyping(suid, attributes.name)),
          addNotifications
        );
      });

      handlePromiseRejection(async () => {
        if (conversation.status === 'joined') {
          const result = await conversation.getParticipants();
          updateParticipants(result, conversation.sid);

          const messages = await conversation.getMessages();
          upsertMessages(conversation.sid, messages.items);
          await loadUnreadMessagesCount(conversation, updateUnreadMessages);
        }
      }, addNotifications);
    });

    client.on('conversationRemoved', async (conversation: Conversation) => {
      updateCurrentConversation('');
      await handlePromiseRejection(async () => {
        removeConversation(conversation.sid);
        updateParticipants([], conversation.sid);
      }, addNotifications);
    });
    client.on('messageAdded', async (message: Message) => {
      await upsertMessage(message, upsertMessages, updateUnreadMessages);
      if (message.author === localStorage.getItem('username')) {
        clearAttachments(message.conversation.sid, '-1');
      }
    });

    client.on('userUpdated', async (event) => {
      await updateUser(event.user);
    });

    client.on('participantLeft', async (participant) => {
      await handlePromiseRejection(async () => handleParticipantsUpdate(participant, updateParticipants), addNotifications);
    });

    client.on('participantUpdated', async (event) => {
      await handlePromiseRejection(async () => handleParticipantsUpdate(event.participant, updateParticipants), addNotifications);
    });

    client.on('participantJoined', async (participant) => {
      await handlePromiseRejection(async () => handleParticipantsUpdate(participant, updateParticipants), addNotifications);
    });

    client.on('conversationUpdated', async ({ conversation }) => {
      await handlePromiseRejection(() => upsertConversation(conversation), addNotifications);
    });

    client.on('messageUpdated', async ({ message }) => {
      await handlePromiseRejection(async () => upsertMessage(message, upsertMessages, updateUnreadMessages), addNotifications);
    });

    client.on('messageRemoved', async (message) => {
      await handlePromiseRejection(() => removeMessages(message.conversation.sid, [message]), addNotifications);
    });

    client.on('pushNotification', (event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (event.type !== 'twilio.conversations.new_message') {
        return;
      }

      if (Notification.permission === 'granted') {
        // Removed firebase integration doesn't work properly
        // showNotification(event);
      } else {
        console.log('Push notification is skipped', Notification.permission);
      }
    });

    client.on('tokenAboutToExpire', async () => {
      refetch();
      await client.updateToken(token as string);
    });

    client.on('tokenExpired', async () => {
      refetch();
      setClientIteration((x) => x + 1);
    });

    client.on('connectionStateChanged', (state) => {
      updateConnectionState(state);
    });

    updateLoadingState(false);

    return () => {
      client?.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIteration, token]);

  // Removed FCM initialization because firebase integration doesn't work properly
  // useEffect(() => {
  //   initFcmServiceWorker().catch(() => {
  //     console.error('FCM initialization failed: no push notifications will be available');
  //   });
  // }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const use24hTimeFormat = localStorage.getItem('use24hTimeFormat');
    if (use24hTimeFormat !== null) {
      updateTimeFormat(true);
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
