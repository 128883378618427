import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MUIDialogTitle, { DialogTitleProps as MUIDialogTitleProps } from '@mui/material/DialogTitle';

import { IconButton, Typography } from '../';

export interface DialogTitleProps extends MUIDialogTitleProps {
  onClickCloseIcon?: (evt: unknown) => void;
}

export function DialogTitle({ children, onClickCloseIcon, ...props }: Readonly<DialogTitleProps>) {
  return (
    <MUIDialogTitle {...props}>
      <Typography variant='subtitle'>{children}</Typography>
      {onClickCloseIcon ? (
        <IconButton
          aria-label='close'
          onClick={onClickCloseIcon}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
      ) : null}
    </MUIDialogTitle>
  );
}
