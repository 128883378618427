import MUIAlertTitle, { AlertTitleProps as MUIAlertTitleProps } from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';

export interface AlertTitleProps extends MUIAlertTitleProps {}

const StyledAlertTitle = styled(MUIAlertTitle)<AlertTitleProps>(() => ({}));

export function AlertTitle(props: AlertTitleProps) {
  return <StyledAlertTitle {...props} />;
}
