import { InfiniteData, QueryFunction, QueryKey, QueryObserverOptions, useInfiniteQuery as _useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { filter, isEmpty, isNil, map, omit, omitBy, pipe } from 'remeda';

import { request } from '@request';
import { mergeData, removeData, removeDuplicateObjects, sortData, UnknownObject, useData, useDebounce } from '@tulp';

import { forumApiRequest } from '../query-fn';
import { ForumApiV3InfiniteResponseDataType } from '../types';

export const DEFAULT_QUERY_PARAMS = {
  page: 0,
  size: 15
};

type QueryFnParams<TParams> = {
  pageParam: string | number;
  signal: AbortSignal;
  baseUrl: string;
  params: TParams;
};

export const InfiniteLogic = {
  inner: {
    queryFn: async <TParams extends UnknownObject = UnknownObject>({ pageParam, signal, baseUrl, params }: QueryFnParams<TParams>) => {
      const response = await request.get(baseUrl, {
        signal,
        params: omitBy(pageParam ? { ...params, page: pageParam } : params, (v) => isNil(v) || isEmpty(v))
      });
      return response.data;
    },
    getNextPageParam: ({ totalPages }: { totalPages: number }, __: unknown, page: number) => {
      if (totalPages - 1 === page) {
        return undefined;
      }
      return page + 1;
    }
  },
  forum: {
    queryFn: async <TParams extends UnknownObject = UnknownObject>({ pageParam, signal, baseUrl, params }: QueryFnParams<TParams>) => {
      const response = await forumApiRequest.get(typeof pageParam === 'string' ? `${baseUrl}?${pageParam}` : baseUrl, {
        signal,
        params: omitBy(params, (v) => isNil(v) || isEmpty(v))
      });
      return response.data;
    },
    getNextPageParam: ({ pagination }: ForumApiV3InfiniteResponseDataType<UnknownObject, 'topics'>) => {
      if (pagination.next.active === false) {
        return undefined;
      }
      return pagination.next.qs;
    }
  }
};

export type PageData<TData> = { [key: string]: TData[] } & {
  elements?: TData[];
  totalElements: number;
  totalPages: number;
};

export type InfiniteQueryArgs<TData extends UnknownObject, TParams, TError = Error, TQueryKey extends QueryKey = QueryKey> = {
  infiniteLogic?: 'inner' | 'forum';
  /**
   * Why "identityData"?
   * The "InfiniteData" structure is a list of pages, each page has a list of elements,
   * but in some cases the elements are not in the "elements" attribute, for this reason
   * we need to specify the attribute that contains the elements
   *
   */
  identityData?: string;
  identityProp?: string;
  queryKey: TQueryKey;
  queryFn?: QueryFunction<PageData<TData>, QueryKey, string | number>;
  baseUrl: string;
  defaultQueryStringParams: TParams;
  queryOptions?: Omit<QueryObserverOptions<PageData<TData>, TError, PageData<TData>, PageData<TData>, TQueryKey>, 'queryKey' | 'queryFn'>;
};

export interface UpdateInfiniteQueryDataArgs<TData> {
  prevData: InfiniteData<PageData<TData>> | undefined;
  items: (TData & { page?: number })[];
  reorderBy?: string[];
  identityProp: string;
  identityData?: string;
  updateFunction: typeof mergeData;
}

function removeLeftmostDuplicates<TData extends UnknownObject>(data: TData[], identityProp: string) {
  const reversed = data.slice().reverse();
  const cleaned = removeDuplicateObjects(reversed, (a, b) => a[identityProp] === b[identityProp]);
  return cleaned.reverse();
}

export function transformQueryData<TData>(data: InfiniteData<PageData<TData>>, identityProp: string, identityData?: string): (TData & { page: number })[] {
  const elements = data?.pages?.reduce(
    // The "page" attr is important here in order to know which page the item belongs to
    (prev, current, currentIndex) => [...prev, ...(current[identityData ?? 'elements'] || []).map((item) => ({ ...item, page: currentIndex }))],
    [] as unknown as TData[]
  ) as unknown as (TData & { page: number })[];

  return removeLeftmostDuplicates<TData & { page: number }>(elements || [], identityProp);
}

export function updateInfiniteQueryData<TData>({ items, prevData, identityProp, identityData, updateFunction, reorderBy }: UpdateInfiniteQueryDataArgs<TData>) {
  if (!prevData) {
    return prevData as unknown as InfiniteData<PageData<TData>>;
  }

  /**
   * Step 1: Add the attribute *page* to the new items
   *
   * The *page* attribute is very important because it's used to create the "InfiniteData" structure
   */
  items = items.map((item) => {
    if (item.page === undefined) {
      const itemInPrevData = transformQueryData(prevData, identityProp, identityData).find(
        (prevItem) => prevItem[identityProp as keyof TData] === item[identityProp as keyof TData]
      );
      item.page = itemInPrevData?.page || 0;
    }
    return item;
  });

  /**
   * Step 2: Create item list. We "merge" the new data(variable "items") with the prev data or
   * "remove" the new data(variable "items") from the prev data.
   *
   * Step 2.1: Transform the query-data into simple list. The prevData has certain structure(watch the type),
   * we transform that structure into a simple list and "attach to each item the *page* attribute to know
   * which page it belongs to and for
   *
   */
  let newItems = updateFunction<TData & { page: number }>(transformQueryData<TData>(prevData, identityProp, identityData), items, identityProp, true);

  // Step 3: Sort the item list
  if (reorderBy) {
    newItems = sortData(newItems, reorderBy);
  }

  /**
   * Step 4: Create the "InfiniteData" structure.
   * Using the *page* attribute, we create the "InfiniteData" structure.
   */
  let pages = prevData.pages;
  pages = pages.map((page, i) => {
    const newElements = pipe(
      newItems,
      filter((newItem) => newItem.page === i),
      map(omit(['page']))
    ) as TData[];
    page.elements = newElements;
    if (identityData) {
      page[identityData] = newElements;
    }
    return page;
  });

  return { pageParams: prevData?.pageParams, pages };
}

export function useInfiniteQuery<TData extends UnknownObject, TParams extends UnknownObject, TError = Error>(
  args: InfiniteQueryArgs<TData, TParams, TError, QueryKey>
) {
  const [params, setParams] = useData<TParams>(args.defaultQueryStringParams);
  const identityProp = args.identityProp || 'id';
  const debouncedParams = useDebounce(params, 500);
  const queryKey = [...args.queryKey, 'list', debouncedParams];
  const infiniteQueryObject = _useInfiniteQuery<PageData<TData>, TError, InfiniteData<PageData<TData>, number>, QueryKey, number | string>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn:
      args?.queryFn ??
      (({ pageParam, signal }) => InfiniteLogic[args.infiniteLogic ?? 'inner'].queryFn({ baseUrl: args.baseUrl, params: debouncedParams, pageParam, signal })),
    initialPageParam: 0,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    getNextPageParam: InfiniteLogic[args.infiniteLogic ?? 'inner'].getNextPageParam,
    ...args.queryOptions
  });

  const queryClient = useQueryClient();

  function handleChangeItems(items: TData[], reorderBy?: string[]) {
    queryClient.setQueryData<InfiniteData<PageData<TData>>>(queryKey, (prevData) =>
      updateInfiniteQueryData({ items, prevData, identityProp, identityData: args.identityData, updateFunction: mergeData, reorderBy })
    );
  }

  function handleRemoveItems(items: TData[]) {
    queryClient.setQueryData<InfiniteData<PageData<TData>>>(queryKey, (prevData) =>
      updateInfiniteQueryData({ items, prevData, identityProp, identityData: args.identityData, updateFunction: removeData })
    );
  }

  function handleClearParams() {
    setParams(args.defaultQueryStringParams);
  }

  const pages = infiniteQueryObject.data?.pages;
  // Improve this logic: apply memoization
  const elements = infiniteQueryObject.status === 'success' ? transformQueryData(infiniteQueryObject.data, identityProp, args.identityData) : [];

  return {
    elements,
    params,
    lastItems: pages?.[pages.length - 1]?.elements || [],
    inTransaction: infiniteQueryObject.isLoading || infiniteQueryObject.isFetching || infiniteQueryObject.isRefetching,
    onChangeParams: setParams,
    onClearParams: handleClearParams,
    onChangeItems: handleChangeItems,
    onRemoveItems: handleRemoveItems,
    ...infiniteQueryObject,
    // On the first render, isFetching=True(maybe it's a bug of RQ) and this affects the logic, for this reason we build this logic:
    isFetching: infiniteQueryObject.isFetching && !infiniteQueryObject.isLoading
  };
}
