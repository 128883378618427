import { useState } from 'react';
import { ButtonBase } from '@mui/material';

import { GuestUserPageContainer } from '@components/GuestUserPageContainer';
import { Alert, Link, Stack, Typography } from '@tulp';

import { resendEmail } from '../../services/userService';

function ValidationPage() {
  const [sentEmail, setSentEmail] = useState(false);

  function resendEmailClick() {
    if (sentEmail) return;
    resendEmail()
      .then(() => {
        setSentEmail(true);
      })
      .catch(() => {});
  }

  return (
    <GuestUserPageContainer>
      <Stack alignItems='center' spacing={4}>
        {sentEmail && (
          <Alert severity='success' onClose={() => setSentEmail(false)}>
            We've sent you another email to validate your account.
          </Alert>
        )}
        <Typography variant='title' gutterBottom>
          Verify your email
        </Typography>
        <Typography paragraph>We've sent an email to you to verify your email address and activate your account.</Typography>
        <Typography paragraph>
          <Link component={ButtonBase} onClick={resendEmailClick}>
            Click here
          </Link>{' '}
          if you did not receive an email.
        </Typography>
        <Typography>
          You can go back to the login page from <Link href={window.location.origin + '/login'}>here</Link>
        </Typography>
      </Stack>
    </GuestUserPageContainer>
  );
}

export default ValidationPage;
