export const URL_API_MAP = {
  dev: 'https://dev-services.saleshookup.com',
  stg: 'https://stg-services.saleshookup.com',
  prod: 'https://services.saleshookup.com'
};

export const FORUM_URL_MAP = {
  dev: import.meta.env.VITE_FORUM_URL_DEV,
  stg: import.meta.env.VITE_FORUM_URL_STG,
  prod: import.meta.env.VITE_FORUM_URL_PROD
};

export const FORUM_URL_API_MAP = {
  dev: `${FORUM_URL_MAP.dev}/api`,
  stg: `${FORUM_URL_MAP.stg}/api`,
  prod: `${FORUM_URL_MAP.prod}/api`
};

export const FORUM_URL_API_V3_MAP = {
  dev: `${FORUM_URL_MAP.dev}/api/v3`,
  stg: `${FORUM_URL_MAP.stg}/api/v3`,
  prod: `${FORUM_URL_MAP.prod}/api/v3`
};

export const URL_API_BASE = URL_API_MAP[(import.meta.env.VITE_ENV ? import.meta.env.VITE_ENV : 'dev') as keyof typeof URL_API_MAP];

export const FORUM_URL_BASE = FORUM_URL_MAP[(import.meta.env.VITE_ENV ? import.meta.env.VITE_ENV : 'dev') as keyof typeof URL_API_MAP];
export const FORUM_URL_API_BASE = FORUM_URL_API_MAP[(import.meta.env.VITE_ENV ? import.meta.env.VITE_ENV : 'dev') as keyof typeof URL_API_MAP];
export const FORUM_URL_API_V3_BASE = FORUM_URL_API_V3_MAP[(import.meta.env.VITE_ENV ? import.meta.env.VITE_ENV : 'dev') as keyof typeof URL_API_MAP];

export const VIDEOSDK_URL_API_BASE = 'https://api.videosdk.live';
