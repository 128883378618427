import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getGroupHookupSettingsByOrganization } from '../query-fn';

export const GroupHookupSettingsQueryStore = createQueryKeys('group-hookup-settings', {
  details: (organizationUUID: string) => ({
    queryKey: [organizationUUID],
    queryFn: (ctx) => getGroupHookupSettingsByOrganization(organizationUUID, ctx)
  })
});
