import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getFutureHookupsForAuthUser, getHookupsForAdminUser, getHookupsForUser, getNextHookupDetails, getTimeCyclesForAdminUser } from '../query-fn';

export const HookupQueryStore = createQueryKeys('hookup', {
  'next-hookup': {
    queryKey: null,
    queryFn: (ctx) => getNextHookupDetails(ctx)
  },
  'all-for-user': {
    queryKey: null,
    queryFn: (ctx) => getHookupsForUser(ctx)
  },
  'all-for-admin-user': {
    queryKey: null,
    queryFn: (ctx) => getHookupsForAdminUser(ctx)
  },
  'future-for-auth-user': {
    queryKey: null,
    queryFn: () => getFutureHookupsForAuthUser()
  }
});

export const TimeCycleQueryStore = createQueryKeys('time-cycle', {
  'all-for-admin-user': {
    queryKey: null,
    queryFn: (ctx) => getTimeCyclesForAdminUser(ctx)
  }
});
