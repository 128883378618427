import * as React from 'react';

export function useToggleState(initialValue: boolean = false) {
  const [state, setState] = React.useState(initialValue);

  const setTrue = React.useCallback(() => {
      setState(true);
    }, []),
    setFalse = React.useCallback(() => {
      setState(false);
    }, []),
    toggle = React.useCallback(() => {
      setState((prevToggleState: boolean) => !prevToggleState);
    }, []);

  return [state, setTrue, setFalse, toggle] as const;
}
