import { DateTime as _DateTime, Duration as _Duration, Interval as _Interval } from 'luxon';

export type DateTimeType = _DateTime;
export const DateTime = _DateTime;
export const Duration = _Duration;
export const Interval = _Interval;

export const DATE_TIME_FORMAT_24 = "dd/MM/yyyy 'at' HH:mm";
export const DATE_TIME_FORMAT = "dd/MM/yyyy 'at' h:mm a";

export const getDateTime = (date: string | Date) =>
  typeof date === 'string' ? DateTime.fromISO(date, { zone: 'utc' }).toLocal() : DateTime.fromJSDate(date, { zone: 'utc' }).toLocal();

export const getDateISOForServer = (date: string | Date) => getDateTime(date).toUTC().toISO({ includeOffset: false, includePrefix: false });

export function formatDateAndTime(date: string | Date, use24HourFormat = true) {
  const datetime = getDateTime(date);
  return datetime.toFormat(use24HourFormat ? DATE_TIME_FORMAT_24 : DATE_TIME_FORMAT);
}

export function formatDate(date: string | Date) {
  const datetime = getDateTime(date);
  return datetime.toLocaleString(DateTime.DATE_SHORT);
}

export function formatTime(time: string | Date) {
  const datetime = getDateTime(time);
  return datetime.toLocaleString(DateTime.TIME_SIMPLE);
}

export function isDateTime(date: string) {
  const datetime = DateTime.fromISO(date);
  return datetime.hour !== 0 || datetime.minute !== 0 || datetime.second !== 0;
}

export function subtractDates(minuend: string | Date, subtrahend: string | Date) {
  return getDateTime(minuend).diff(getDateTime(subtrahend));
}

const units: Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

export const getRelativeFormat = (date: string | Date) => {
  const datetime = getDateTime(date);
  const diff = datetime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto'
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};
