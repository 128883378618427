import { Client, ConnectionState } from '@twilio/conversations';
import { Action, combineReducers } from 'redux';

import attachmentsReducer, { AttachmentsState } from './attachmentsReducer';
import clientReducer from './clientReducer';
import connectionStateReducer from './connectionStateReducer';
import convoReducer, { ReduxConversation } from './convoReducer';
import sidReducer from './currentConvoReducer';
import lastReadIndexReducer from './lastReadIndexReducer';
import loadingReducer from './loadingReducer';
import messageReducer, { ChatMessagesState } from './messageListReducer';
import notificationsReducer, { NotificationsType } from './notificationsReducer';
import participantReducer, { ParticipantsType } from './participantsReducer';
import timeReducer from './timeReducer';
import tokenReducer from './tokenReducer';
import typingDataReducer, { TypingDataState } from './typingDataReducer';
import unreadMessagesReducer, { UnreadMessagesState } from './unreadMessagesReducer';
import userReducer, { UsersState } from './userReducer';

export type AppState = {
  client: Client;
  connectionState: ConnectionState;
  token: string;
  convos: ReduxConversation[];
  sid: string;
  messages: ChatMessagesState;
  unreadMessages: UnreadMessagesState;
  loadingStatus: boolean;
  participants: ParticipantsType;
  users: UsersState;
  attachments: AttachmentsState;
  typingData: TypingDataState;
  lastReadIndex: number;
  notifications: NotificationsType;
  use24hTimeFormat: boolean;
};

export const initialState = {
  client: null,
  connectionState: 'unknown' as ConnectionState,
  token: '',
  sid: '',
  messages: {},
  attachments: {},
  participants: {},
  users: {},
  convos: [],
  unreadMessages: {},
  loadingStatus: true,
  typingData: {},
  lastReadIndex: -1,
  notifications: [],
  use24hTimeFormat: false
};

const reducers = (state: AppState | undefined, action: Action): ReturnType<typeof appReducer> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return appReducer(state, action);
};

const appReducer = combineReducers({
  client: clientReducer,
  connectionState: connectionStateReducer,
  token: tokenReducer,
  convos: convoReducer,
  sid: sidReducer,
  lastReadIndex: lastReadIndexReducer,
  messages: messageReducer,
  loadingStatus: loadingReducer,
  participants: participantReducer,
  users: userReducer,
  unreadMessages: unreadMessagesReducer,
  attachments: attachmentsReducer,
  typingData: typingDataReducer,
  notifications: notificationsReducer,
  use24hTimeFormat: timeReducer
});

export default reducers;
