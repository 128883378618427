import { useState } from 'react';
import { Theme } from '@mui/material';

import { getJourneyIframeLinkByLocation } from '@utils/journey';
import { Box, Button, Drawer, DrawerProps, Skeleton, Toolbar, useMediaQuery } from '@tulp';

export type OnboardingSideDrawerPropType = {
  open: boolean;
  drawerWidth: number;
  onClose: DrawerProps['onClose'];
};

export function OnboardingSideDrawer(props: Readonly<OnboardingSideDrawerPropType>) {
  const [loadingTutorial, setLoadingTutorial] = useState(true);

  const upXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  return (
    <Drawer
      variant={upXl ? 'persistent' : 'temporary'}
      anchor='right'
      open={props.open}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      onClose={props.onClose}
    >
      <Box width={props.drawerWidth} height='100%'>
        <Toolbar />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Button size='small' sx={{ float: 'right', mt: 2, mr: 2 }} onClick={props.onClose}>
          Close
        </Button>
        {loadingTutorial ? <Skeleton variant='rectangular' sx={{ width: props.drawerWidth, height: '100vh' }} /> : null}
        <iframe
          allowFullScreen
          title='Onboarding Tutorial'
          style={{ border: 0, height: '100%', width: '100%' }}
          src={`https://saleshookup.journey.io/p/${getJourneyIframeLinkByLocation()}`}
          onLoad={() => setLoadingTutorial(false)}
        />
      </Box>
    </Drawer>
  );
}
