const ENV = import.meta.env.VITE_ENV ? import.meta.env.VITE_ENV : 'dev';

const CONF = {
  dev: {
    BACKEND_URL: 'https://dev-services.saleshookup.com',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4',
    URL: 'https://dev.saleshookup.com/',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyDS9G31SsPel8tghlkVjzOSe9h5Nov4u_k',
      authDomain: 'saleshookup-53598.firebaseapp.com',
      projectId: 'saleshookup-53598',
      storageBucket: 'saleshookup-53598.appspot.com',
      messagingSenderId: '356973483508',
      appId: '1:356973483508:web:6c9a8c60cb475f3b24d5a1',
      measurementId: 'G-SK3R5XKP0E'
    }
  },
  stg: {
    BACKEND_URL: 'https://stg-services.saleshookup.com',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4',
    URL: 'https://stg.saleshookup.com/',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyDS9G31SsPel8tghlkVjzOSe9h5Nov4u_k',
      authDomain: 'saleshookup-53598.firebaseapp.com',
      projectId: 'saleshookup-53598',
      storageBucket: 'saleshookup-53598.appspot.com',
      messagingSenderId: '356973483508',
      appId: '1:356973483508:web:6c9a8c60cb475f3b24d5a1',
      measurementId: 'G-SK3R5XKP0E'
    }
  },
  prod: {
    BACKEND_URL: 'https://services.saleshookup.com',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4',
    URL: 'https://app.saleshookup.com/',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyDS9G31SsPel8tghlkVjzOSe9h5Nov4u_k',
      authDomain: 'saleshookup-53598.firebaseapp.com',
      projectId: 'saleshookup-53598',
      storageBucket: 'saleshookup-53598.appspot.com',
      messagingSenderId: '356973483508',
      appId: '1:356973483508:web:6c9a8c60cb475f3b24d5a1',
      measurementId: 'G-SK3R5XKP0E'
    }
  },
  loc: {
    BACKEND_URL: 'https://dev-services.saleshookup.com',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4',
    URL: 'https://dev.saleshookup.com/',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyDS9G31SsPel8tghlkVjzOSe9h5Nov4u_k',
      authDomain: 'saleshookup-53598.firebaseapp.com',
      projectId: 'saleshookup-53598',
      storageBucket: 'saleshookup-53598.appspot.com',
      messagingSenderId: '356973483508',
      appId: '1:356973483508:web:6c9a8c60cb475f3b24d5a1',
      measurementId: 'G-SK3R5XKP0E'
    }
  }
};

if (!CONF[ENV]) {
  // here we break the app because the environment variables are wrong
  throw new Error(`Missing config ${ENV}!`);
}

export const ENVIRONMENTS = CONF[ENV];
