import * as z from 'zod';

import { UserNameValidationSchema } from './common';
import { getMsgMax, getMsgMin } from './common-messages';

export const LoginFormValidationSchema = z.object({
  username: UserNameValidationSchema,
  password: z.string().min(8, getMsgMin('Password', 8)).max(250, getMsgMax('Password', 250))
});

export type LoginFormValidationSchemaType = z.infer<typeof LoginFormValidationSchema>;
