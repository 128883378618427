import { request } from '@request';

import { BASE_URL } from '../utils/consts';

export function getNotifications(params) {
  let submitParams = {
    page: params?.page ? params.page : 0,
    size: params?.size ? params.size : 5,
    sorting_direction: 'DESC',
    sorting_parameter: 'date'
  };
  return request.get(
    `${BASE_URL}/notification/list?` +
      `page=${submitParams.page}&` +
      `size=${submitParams.size}&` +
      `sorting_direction=${submitParams.sorting_direction}&` +
      `sorting_parameter=${submitParams.sorting_parameter}`
  );
}

export function setAsOld() {
  return request.put(`${BASE_URL}/notification/mark-as-not-new`);
}

export function setAsViewed(params) {
  return request.put(`${BASE_URL}/notification/mark-as-viewed`, params);
}
