import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { loginCallback } from '@utils/auth';

import { verifyUserEmail } from '../../services/userService';
import { setRememberedItem } from '../../utils/storage';

export default function VerificationEmail() {
  const params = new URLSearchParams(useLocation().search);
  const [error, setError] = useState();

  useEffect(() => {
    verifyUserEmail({ confirmationToken: params.get('token') })
      .then((response) => {
        setRememberedItem('askCalendarSync', true);
        setRememberedItem('askForImage', true);

        loginCallback(response.data, '/profile');
      })
      .catch((err) => {
        setError(err.response?.data?.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{error ? { error } : null}</>;
}
