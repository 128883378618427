import * as z from 'zod';

import { CreateGroupHookupValidationSchema } from '@form';

import { VideoSdkRoomType } from '.';

export enum GroupHookupStatusType {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  'NOT_ACCEPTED' = 'NOT ACCEPTED'
}

export enum GroupHookupDefaultCategoryType {
  'BIG_EVENT' = 'BIG_EVENT'
}

export type CreateGroupHookupReqBodyType = z.infer<typeof CreateGroupHookupValidationSchema>;

export type RejectGroupHookupParamsType = {
  uuid: string;
  reason: string;
};

export type CancelGroupHookupParamsType = {
  uuid: string;
  reason: string;
};

export type CreateGroupHookupResType = {
  title: string;
  category: string;
  startDate: string;
  duration: number;
  description?: string;
};

export type GetGroupHookupQueryParamsType = {
  uuid: string;
};

export type GroupHookupCategoryDetailsType = {
  id: string;
  uuid: string;
  createdOn: string;
  updatedOn: string;
  code: string;
  color: string;
  name: string;
  defaultDuration: number;
  defaultParticipantsLimit: number;
  enabled: boolean;
  icon: string | null;
  organizationUuid: string;
};

export interface GroupHookupItemListType {
  uuid: string;
  hostFullName: string;
  title: string;
  category: string;
  startDate: string; // ISO date-time string
  status: keyof typeof GroupHookupStatusType;
  duration: number;
  slotsLeft: number;
  hostImageUrl: string;
  description: string | null;
  highlighted: boolean;
  participants: string[];
}

export interface GroupHookupParticipantType {
  id: string;
  uuid: string;
  fullName: string;
  imageUrl: string | null;
}

export interface GroupHookupDetailsType {
  id: string;
  uuid: string;
  title: string;
  host: string;
  category: string;
  participants: GroupHookupParticipantType[];
  videoCallId: string;
  startDate: string; // ISO date-time string
  duration: number;
  nonApprovedReason: null | string; // replace string with the actual type if known
  status: keyof typeof GroupHookupStatusType;
  description: string;
  roomResponse: VideoSdkRoomType;
  token: string | null;
  slotsLeft: number;
  canUserJoin: boolean;
  hostParticipant: GroupHookupParticipantType;
  participantsUUIDs: string[];
  userNeedsToAttendFirst: (authenticatedUserUUID: string) => boolean;
  isHost: (authenticatedUserUUID: string) => boolean;
}

export interface GroupHookupConnectionItemListType {
  userUuid: string;
  id: string;
  fullName: string;
  username: string;
  jobPosition: string;
  companyName: string;
  imageUrl: string;
  linkedinUrl?: string;
  groupHookupName: string | null;
  groupHookupDate: string;
}
