import * as Sentry from '@sentry/react';

import { RegisterFormValidationSchemaType } from '@form';
import { UserDataType } from '@query';

import { SentryMetricKeys } from './map';

export function trackSignup(tags: RegisterFormValidationSchemaType) {
  Sentry.metrics.increment(SentryMetricKeys.Signups, 1, {
    tags: {
      ...tags,
      location: tags.location.name
    }
  });
}

export function trackNotFoundPage(user?: UserDataType) {
  Sentry.metrics.increment(SentryMetricKeys.NotFoundPage, 1, {
    tags: {
      userUUID: user?.uuid ?? '',
      userFullName: user?.fullName ?? '',
      userEmail: user?.username ?? ''
    }
  });
}
