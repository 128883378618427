import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getJobPosition } from '../query-fn';

export const JobPositionQueryStore = createQueryKeys('job-position', {
  all: {
    queryKey: null,
    queryFn: (ctx) => getJobPosition(ctx)
  }
});
