import { QueryFunctionContext } from '@tanstack/react-query';

import { request } from '@request';
import { formatDateAndTime, UnknownObject } from '@tulp';

import { QueryStringParamsType, UsersQueryParamsType } from '../queries';
import {
  MailingPreferencesDataType,
  MailingPreferencesMutationDataType,
  OrganizationItemListType,
  PaginatedDataType,
  QueryParamsWithSignal,
  TopActiveMemberDataType,
  UserDataType
} from '../types';
import { attachQueryParams } from '../utils';
import { downloadGet, URL_API_BASE } from '.';

export async function getAuthenticatedUser(args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/user/details`, { signal: args?.signal });
  return res.data as UserDataType;
}

export async function getAuthUserTotalHookups({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/user/total-hookups`, { signal });
  return res.data as number;
}

export async function getTopActiveMembers({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/user/top-active-members`, { signal });
  return res.data as TopActiveMemberDataType[];
}

export async function getAuthUserMailingPreferences({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/user/mailing-preferences`, { signal });
  return res.data as MailingPreferencesDataType;
}

export async function getUserDetailByUsernameOrFullName({ signal, params }: QueryParamsWithSignal<{ key: string }>) {
  const res = await request.get(`${URL_API_BASE}/user?fullName=${params?.key}`, { signal });
  return res.data as UserDataType;
}

export async function updateAuthUserMailingPreferences({ data }: { data: MailingPreferencesMutationDataType }) {
  const res = await request.put(`${URL_API_BASE}/user/mailing-preferences`, { data });
  return res.data as UserDataType;
}

export async function pauseAuthUserNotifications(weeks: number) {
  const res = await request.post(weeks ? `${URL_API_BASE}/user/pause-notifications?weeks=${weeks}` : `${URL_API_BASE}/user/pause-notifications`);
  return res.data as UserDataType;
}

export async function unPauseAuthUserNotifications() {
  const res = await request.post(`${URL_API_BASE}/user/unpause-notifications`);
  return res.data as UserDataType;
}

export async function unsubscribeUser(token: string, email: string) {
  const res = await request.put(`${URL_API_BASE}/unsubscribe?token=${token}&email=${email}`);
  return res.data;
}

export async function getUsersPaginated(params: QueryStringParamsType<UnknownObject>, args?: QueryFunctionContext | undefined) {
  const res = await request.get(attachQueryParams(`${URL_API_BASE}/admin/users`, params), { signal: args?.signal });
  return res.data as PaginatedDataType<UserDataType>;
}

export function exportUsersToCsv(params: UsersQueryParamsType) {
  const csvFileName = `Users-Report-${formatDateAndTime(new Date())}.csv`;
  return downloadGet(`${URL_API_BASE}/admin/users/export`, params, csvFileName);
}

export async function getUserOrganizations(args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/user/organization`, { signal: args?.signal });
  return res.data as OrganizationItemListType[];
}

export async function disableUser(uuid: string, args?: QueryFunctionContext | undefined) {
  const res = await request.put(`${URL_API_BASE}/admin/user/disable/${uuid}`, { signal: args?.signal });
  return res.data as UserDataType;
}

export async function getUserData(uuid: string, args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/user/details/${uuid}`, { signal: args?.signal });
  return res.data as UserDataType;
}

export async function getUserDataAsAdmin(uuid: string, args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/admin/user/${uuid}`, { signal: args?.signal });
  return res.data as UserDataType;
}
