import { redirect } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { forumGetTopicData, UserDataType, UserQueryStore } from '@query';

import { redirectToLogin, redirectToNotFound } from '.';

export const getAuthUserLoader = (queryClient: QueryClient) => async (): Promise<UserDataType> => {
  const query = UserQueryStore.authenticated;
  try {
    const queryResponse: UserDataType = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery({ ...query }));
    return queryResponse;
  } catch (error) {
    throw redirectToLogin() as unknown as Promise<UserDataType>;
  }
};

export const hasPermissionsLoader =
  (queryClient: QueryClient, roles: string[], redirectURL: string = '/home') =>
  async () => {
    const authUser = await getAuthUserLoader(queryClient)();
    if (!authUser.roles.some((role) => roles.includes(role))) {
      throw redirect(redirectURL);
    }
    return authUser;
  };

export const forumGetTopicLoader = async ({ params }: { params: { postId: string } }) => {
  if (params.postId) {
    try {
      return await forumGetTopicData(params.postId as unknown as number);
    } catch (error) {
      throw redirectToNotFound();
    }
  } else {
    throw redirectToNotFound();
  }
};
