import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';

import { ForumSettingsDataType } from '@query';
import { LOCAL_STORAGE_KEYS } from '@utils/consts';
import { localStore } from '@tulp';

import { FORUM_URL_API_BASE, FORUM_URL_API_V3_BASE, FORUM_URL_BASE } from '../';

export const forumRequest = axios.create({
  baseURL: FORUM_URL_BASE,
  withCredentials: true
});

export const forumApiRequest = axios.create({
  baseURL: FORUM_URL_API_BASE,
  withCredentials: true
});

export const forumApiV3Request = axios.create({
  baseURL: FORUM_URL_API_V3_BASE,
  withCredentials: true
});

export async function getForumSettings() {
  const res = await forumApiRequest.get('/config');
  return res.data as ForumSettingsDataType;
}

export async function getMe(params?: QueryFunctionContext) {
  const res = await forumApiRequest.get('/me', params);
  return res.data;
}

export async function forumPostPing() {
  const res = await forumApiV3Request.post('/ping');
  return res.data;
}

export async function forumGetPing() {
  const res = await forumApiV3Request.get('/ping');
  return res.data;
}

export async function forumLogin() {
  let res;
  try {
    res = await getMe();
    localStore(LOCAL_STORAGE_KEYS.NodeBBUserSlug, res.split('/')[2]);
  } catch (error) {
    res = await forumRequest.get('/login');
    res = await getMe();
    localStore(LOCAL_STORAGE_KEYS.NodeBBUserSlug, res.split('/')[2]);
  }
  return res.data;
}

export async function forumLogout() {
  const res = await forumRequest.post('/logout');
  return res.data;
}
