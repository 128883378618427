import { forwardRef } from 'react';
import { ReactGoogleAutocompleteProps, usePlacesWidget } from 'react-google-autocomplete';

import { TextField, TextFieldProps } from '../TextField';

export type LocationSelectPropsType = Pick<ReactGoogleAutocompleteProps, 'apiKey' | 'onPlaceSelected'> &
  TextFieldProps & {
    apiKey: string;
    onPlaceSelected: (place: unknown) => void;
  };

export const LocationSelect = forwardRef((props: LocationSelectPropsType, ref) => {
  const { apiKey, onPlaceSelected, ...textFieldProps } = props;
  const { ref: materialRef } = usePlacesWidget({
    apiKey: apiKey,
    inputAutocompleteValue: 'country',
    onPlaceSelected: onPlaceSelected
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return <TextField {...textFieldProps} inputRef={materialRef} ref={ref} />;
});

LocationSelect.displayName = 'LocationSelect';
