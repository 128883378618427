import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { getQueryParamFromUrlSearch } from '@tulp';

export default function LinkedIn() {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const code = getQueryParamFromUrlSearch(window.location.search, 'code');
    const token = getQueryParamFromUrlSearch(window.location.search, 'token');
    const state = getQueryParamFromUrlSearch(window.location.search, 'state');
    console.log('code', code);
    console.log('token', token);
    console.log('state', state);
    if (code) {
      setErrorMessage(code || 'Login failed. Please try again.');
      window?.opener?.postMessage(
        {
          error: code,
          state: state,
          from: 'Linked In'
        },
        window.location.origin
      );

      window.close();
    }
    if (token) {
      const nextQueryParam = getQueryParamFromUrlSearch(window.location.search, 'next');
      window?.opener?.postMessage({ token: token, state: state, from: 'Linked In' }, nextQueryParam ?? window.location.origin);
    }
  }, []);
  return (
    <>
      <Outlet />
      {errorMessage && <>{errorMessage}</>}
    </>
  );
}
