import { Container } from '@mui/material';
import MUIAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { ENVIRONMENTS } from '@config/environments';
import { QueryClientProvider } from '@query';
import { Box, Button, ButtonLink, ContainerProps, Stack, ThemeProvider, useMediaQuery, useTheme } from '@tulp';

import logo from '../../images/app_bar_logo.png';

export type GuestUserPageContainerPropsType = {
  containerMaxWidth?: ContainerProps['maxWidth'];
  needsLogin?: boolean;
  children: React.ReactNode;
};

const LoginURL = `${ENVIRONMENTS.URL}login`;
const RegisterURL = `${ENVIRONMENTS.URL}register`;
const HomeURL = `${ENVIRONMENTS.URL}home`;

export function GuestUserPageContainer(props: Readonly<GuestUserPageContainerPropsType>) {
  const theme = useTheme();
  const downOfMd = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  return (
    <QueryClientProvider>
      <ThemeProvider>
        <MUIAppBar variant='outlined' elevation={0} position='fixed' color='inherit' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar sx={{ mx: 6 }}>
            <Box display='flex' alignItems='center' flexGrow={1} component='a' href={props.needsLogin ? LoginURL : HomeURL}>
              <img style={{ width: downOfMd ? 150 : 200 }} src={logo} alt='SalesHookup logo' />
            </Box>
            {props.needsLogin ? (
              <Stack spacing={2} direction='row'>
                <Button href={LoginURL} size='small' variant='outlined'>
                  Log in
                </Button>
                <Button href={RegisterURL} size='small' variant='outlined'>
                  Sign up
                </Button>
              </Stack>
            ) : (
              <ButtonLink to={HomeURL} size='small' variant='outlined'>
                Go to Home
              </ButtonLink>
            )}
          </Toolbar>
        </MUIAppBar>
        <Toolbar />
        <Container maxWidth={props.containerMaxWidth ?? 'md'} sx={{ mt: 12, pb: 12 }}>
          {props.children}
        </Container>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
