import { QueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { CookieAttributes } from 'node_modules/@types/js-cookie';

import { forumLogout, LoginTokenDataType, UserQueryStore } from '@query';
import { getQueryParamFromUrlSearch } from '@tulp';

import { clearSession, setSession } from './session';
import { removeItem, setRememberedItem } from './storage';

export const FORUM_TOKEN_COOKIE = 'forum_token';
export const FORUM_TOKEN_OPTIONS: CookieAttributes = { domain: import.meta.env.VITE_FORUM_COOKIE_TOKEN_DOMAIN, sameSite: 'Lax' };

export function setForumTokenCookie(token: string) {
  Cookies.set(FORUM_TOKEN_COOKIE, token, FORUM_TOKEN_OPTIONS);
}

function _loginCallback(token: string, rtn: string, forumToken: string, to: string) {
  setSession({ token });
  setRememberedItem('RTN', rtn);

  setForumTokenCookie(forumToken);

  const nextQueryParam = getQueryParamFromUrlSearch(window.location.search, 'next');
  window.location.href = window.location.origin + (nextQueryParam ?? to);
}

export function linkedInLoginCallback(tokenData: Omit<LoginTokenDataType, 'refreshToken' | 'token'> & { accessToken: string }, token: string) {
  _loginCallback(tokenData.accessToken, token, tokenData.forumToken, '/home');
}

export function loginCallback(tokenObject: LoginTokenDataType, to: string) {
  _loginCallback(tokenObject.token, tokenObject.refreshToken, tokenObject.forumToken, to);
}

export async function logoutCallback(queryClient: QueryClient, to: string) {
  Cookies.remove(FORUM_TOKEN_COOKIE, FORUM_TOKEN_OPTIONS);
  clearSession();
  removeItem('RTN');

  await forumLogout();

  window.location.href = window.location.origin + to;
  queryClient.invalidateQueries({ queryKey: UserQueryStore.authenticated.queryKey });
}
