import * as StorageUtil from './storage';

const SESSION_KEY = 'session';

export function clearSession() {
  StorageUtil.setItem(SESSION_KEY, '{}');
}

export function getSession() {
  return JSON.parse(StorageUtil.getItem(SESSION_KEY) || '{}');
}

export function setSession(session) {
  StorageUtil.setItem(SESSION_KEY, JSON.stringify(session));
}
